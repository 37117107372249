import "../Login/Login.css";
import Logo from "../../Recursos/Img/logo_gapl.png";
import Footer from "../../Componentes/Footer/Footer";
import loginServicio from "../../Servicios/Login";
import React, { useState, useEffect } from "react";
import { AuthContext } from "../../Recursos/AuthContext";
import { useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alertas from "../../Recursos/Alertas";
import Swal from "sweetalert2";
import { helpHttp } from "../../Recursos/helpHttp";
const api = helpHttp();

function NotFound({ logout = false }) {
  const [identificacion, setIdentificacion] = useState("");
  const [clave, setClave] = useState("");
  const [version, setVersion] = useState("v0.0.0.0");
  const history = useHistory();
  const { setUserInfo } = React.useContext(AuthContext);

  if (logout === true) {
    setUserInfo(null);
    Alertas.toastInfo("Sesion vencida");
  }

  /* -------------------------------------------Ojo de la contraseña------------------------------------------------------- */
  useEffect(() => {
    var btn = document.querySelector(".formulario__validacion-eye");
    btn.addEventListener("click", function (mostrar) {
      const inputpassword = document.querySelector("#password");
      var ojo = document.querySelector("#Eye");
      if (mostrar.target.classList.contains("show")) {
        mostrar.target.classList.remove("show");
        ojo.setAttribute(
          "src",
          "https://static.xx.fbcdn.net/rsrc.php/v3/yk/r/swFqSxKYa5M.png"
        );
        inputpassword.type = "text";
      } else {
        mostrar.target.classList.add("show");
        ojo.setAttribute(
          "src",
          "https://static.xx.fbcdn.net/rsrc.php/v3/yZ/r/je5FEJkU1_K.png"
        );
        inputpassword.type = "password";
      }
    });

    const getVersion = async () => {
      const res = await api.get("Version");
      if (res.success) {
        setVersion(res.value);
      } else {
        Swal.fire({
          title: "Hola, te pedimos disculpas",
          text: "Por el momento estamos fuera de servicio, intenta de nuevo más tarde!",
          confirmButtonColor: "blue",
        });
      }
    };
    getVersion();
  }, []);

  const iniciarSesion = async (event) => {
    event.preventDefault();

    await loginServicio.Login({ identificacion, clave })
      .then(res => {
        if (res.success) {
          setUserInfo(identificacion);
          history.push("/Home");
        }
        else if(res.errors){
          Alertas.toastError(res.errors[0].message)
        }
    });
    
  };

  return (
    <div>
      <div className="container">
        <form onSubmit={iniciarSesion} className="formulario_inicioAPL">
          {/* <!----------------------------grupo imagen---------------------------> */}
          <div className="ImagenAPL">
            <img src={Logo} width="300" />
          </div>
          {/* <!-------------------------------Titulo----------------------------> */}
          <h1 className="registro">Iniciar sesión</h1>

          <p className="line">______________________________________________</p>

          {/* <!------------------------Grupo id--------------------------------> */}
          <div className="grupo_nit">
            <label htmlFor="id" className="grupo_label1">
              <b>NIT</b>
            </label>
            <div className="formulario_grupo-input">
              <input
                value={identificacion}
                onChange={({ target }) => setIdentificacion(target.value)}
                className="formulario_input"
                type="text"
                id="id"
              />
            </div>
          </div>

          {/* <!------------------------------Grupo contraseña--------------------> */}
          <div className="grupo_contraseña" id="grupo_password">
            <label htmlFor="password" className="grupo_label2">
              <b>Contraseña</b>
            </label>
            <div className="formulario_grupo-input">
              <input
                value={clave}
                onChange={({ target }) => setClave(target.value)}
                className="formulario_input"
                type="password"
                id="password"
              />
              <span className="formulario__validacion-eye">
                <img
                  id="Eye"
                  src="https://static.xx.fbcdn.net/rsrc.php/v3/yZ/r/je5FEJkU1_K.png"
                />
              </span>
            </div>
          </div>

          {/* <!------------------------------- Grupo Mensaje de error------------------------> */}

          <div className="formulario__mensaje" id="formulario__mensaje">
            <p>
              <i className="fas fa-exclamation-triangle"></i>
              <b> Error: </b>El NIT o la contraseña es incorrecto
            </p>
          </div>

          {/* <!---------------------------------------Grupo Boton----------------------------------> */}
          <div style={{ marginTop: "20px" }} className="grupo_boton">
            <button id="ingresar" type="submit" className="boton">
              Ingresar
            </button>
          </div>
          <div className="row m-4">
            <div className="col">
              <span className="version">{version}</span>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
      <Footer />
    </div>
  );
}
export default NotFound;
