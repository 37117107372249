import React from "react";
import { Form} from "react-bootstrap";
import "./Number.css"
export const Input = ({ type, name, values, handleChange, label,id , disabled }) => {
  return (
    <>
        <Form.Label
          style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
        >
          {label}<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          className="input"
          type={type}
         
          name={name}
          value={values}
          onChange={handleChange}
          id={id}
          disabled={disabled}
        />
    </>
  );
};
