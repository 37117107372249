

import React, { useEffect, useState } from 'react'
import { Badge } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { InputEdit } from "../../Componentes/Input/InputEdit";
import Alertas from '../../Recursos/Alertas';
import { helpHttp } from '../../Recursos/helpHttp';

const initailForm = {
    clave: '',
    nuevaClave: '',
    identificacion: ''
}
const Usuario = () => {

    const [usuario, setUsuario] = useState({}); 

    useEffect(() => {
        setUsuario(JSON.parse(localStorage.getItem('user')))
        console.log(usuario)
    },[localStorage.getItem('user')]);

    const api = helpHttp()

    const [form, setForm] = useState(initailForm);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };
//80400934104012026
    const cambiarClave = async (e) => {
        e.preventDefault();

        if (form.identificacion && (usuario.tipoUsuario === 1 || form.clave) && form.nuevaClave) {

            let options = {
                headers: { "content-type": "application/json" },
                body: form
            };

            await api.put('Usuario/Clave', options)
            .then((res) => {
                if (res.success) {
                    Alertas.toastSuccess("Contraseña actualiza")
                }
                else if(res.errors){
                    Alertas.toastError(res.errors[0].message)
                }
            })
            
        } else {
            Alertas.toastWarning("Complete los campos obligatorios")
        }
    }

    return (
        <div>
            <header className="titulo mb-5">
                <h1>
                    <Badge className="tituloPrincipal" variant="light">
                        Usuario
                    </Badge>
                </h1>
                <hr></hr>
            </header>

            <Form className="mb-5" onSubmit={cambiarClave}>
                <div className="row justify-content-md-center mb-3">
                    <div className="col col-lg-4">
                        <InputEdit
                            type="text"
                            name="identificacion"
                            handleChange={handleChange}
                            value={form.identificacion}
                            label="NIT"
                        />
                    </div>
                </div>{usuario.tipoUsuario === 2 && (
                        <div className="row justify-content-md-center mb-3">
                            <div className="col col-lg-4">
                                <InputEdit
                                    type="password"
                                    name="clave"
                                    handleChange={handleChange}
                                    value={form.clave}
                                    label="Contraseña Actual"
                                />
                            </div>
                        </div>
                    )}
                <div className="row justify-content-md-center mb-5">
                    <div className="col col-lg-4">
                        <InputEdit
                            type="password"
                            name="nuevaClave"
                            handleChange={handleChange}
                            value={form.nuevaClave}
                            label="Nueva Contraseña"
                        />
                    </div>
                </div>
                <div className="row justify-content-md-center">
                    <button type="submit" class="btn btn-success">Guardar</button>
                </div>
              
            </Form>
        </div>
    )
}

export default Usuario
