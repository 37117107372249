import { helpHttp } from "../Recursos/helpHttp";

const Login = async (credenciales) => {
 
    var api = helpHttp();

    let options = {
        body: credenciales,
        headers: { "content-type": "application/json" },
    };
    
    var login = await api.post('Token/Generar', options)
   

    if(login.success){
        localStorage.setItem('user', JSON.stringify(login.value))
    }
    
    return login
}

export default { Login }