import React, { useState, useEffect } from "react";
import DataTable from 'react-data-table-component';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { useHistory } from "react-router";
import fechaUtils from "../../Utilidades/fechaUtils";
import { Select2 } from "../../Componentes/Select/Select2";
import { Loader } from "../../Recursos/Loader";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from '../../Recursos/Alertas'
import fileUtils from '../../Utilidades/fileUtils'
import { Form} from "react-bootstrap";
var qs = require('qs');

let api = helpHttp();

/*------------------------ Overlay----------------------------------- */
const editar = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Editar empleado
  </Tooltip>
)  

const habilitar_overlay = (props) => {
  return <Tooltip id="button-tooltip" {...props}>
    desabilitar / habilitar
  </Tooltip>
} 

export const TablaEmpleados = ({ setDataToEdit, deleteData, selectedRows, setSelectedRows }) => {


  let history = useHistory();
  const [empleado, setEmpleado] = useState({ label: 'Seleccione', value: 0 });
  const [data, setData] = useState(null);
  const [habilitar, setHabilitar] = useState(0);
  const [seleccionados, setSeleccionados] = useState(0);

 
  const handleEdit = (personaId, contratoId) => {
    setDataToEdit({ personaId, contratoId });
    history.push(`/Home/Lista/Empleados/editar/${personaId}`);
  };

  async function HabilitarDesHabilitar(habilitado, contratoId) {

    var data = {
      contratoId,
      habilitado
    }

    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };

    var res = await api.put("Empleado/Habilitar", options)

    if(res.success){
      Alertas.toastSuccess(`Empleado ${habilitado ? 'Habilitado' : 'Deshabilitado'} con exito`)
      // document.getElementById(`hab-${contratoId}`).style.color = habilitado ? "gray" : "red"
      setHabilitar(habilitar + 1)
    }
    
  }

  useEffect(() => {

   
    async function filtarTabla() {

      setData(null);
      var res = await api.get(`Empleado/Lista?empleadoId=${empleado.value}`);

      if (res.success) {
        setData(res.value);
        // Alertas.toastSuccess("Filtro Aplicado", 1000, true)
        // 
      } 
    }

    filtarTabla();

  }, [empleado, habilitar]);
 
  const justifyContentCenter = { 
    justifyContent: "center",
  };
 
  const columns = [
    {
      name: 'Identificacion',
      selector: row => row.identificacion,
      minWidth: "150px",
      maxWidth: "150px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
      // maxWidth: '350px',
      sortable: true,
    },
    {
      name: 'Correo',
      selector: row => row.correo,
      maxWidth: '270px',
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: 'Ingreso',
      selector: row => fechaUtils.formatoFecha(row.fechaIngreso),
      minWidth: '110px',
      maxWidth: '110px',
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: 'Tipo',
      selector: row => row.tipoTrabajador,
      maxWidth: '130px',
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: 'Sueldo',
      sortable: true,
      selector: row => new Intl.NumberFormat('en-GB').format(row.sueldo),
      maxWidth: '100px'
    },
    {
      cell: (row) =>
        <div className="d-flex justify-content-evenly ">
          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={editar}
              transition={false}>
              <a style={{ cursor: "pointer" }} size="sm" onClick={() => handleEdit(row.personaId, row.contratoId)}>
                <i className="fas fa-user-edit"></i>
              </a>
            </OverlayTrigger>
          </div>
          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={habilitar_overlay}
              transition={false}>
              <a style={{ cursor: "pointer", color: row.habilitado ? "gray" : "red" }} size="sm" id={"hab-" + row.contratoId}
                onClick={ ()=> {
                  row.habilitado = !row.habilitado;
                  HabilitarDesHabilitar(row.habilitado, row.contratoId);
                }}>
                <i className="fas fa-ban"></i>
              </a>
            </OverlayTrigger>
          </div>
        </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,

    },
  ];

  const conditionalRowStyles = [
    {
      when: row => row.habilitado ===false,
      style: {
        backgroundColor: '#ffe5e5',
        // color: 'white', 
      },
    },
  ]

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setSeleccionados(selectedRows.length)
  };


  const rowSelectCritera = row => !row.habilitado;

  function limpiarFiltros() {
    setEmpleado({ label: 'Seleccione', value: 0 })
  }

  async function descargarInforme(){
   
    if(selectedRows.length > 0) {
 
      let param = {
        ids : selectedRows.map(x => x.personaId)
      }
      let stringify = qs.stringify( param, {arrayFormat: 'repeat', encode: false})
      console.log('descargar', stringify)

      await api.get(`Nomina/Informe?${stringify}`)
      .then(res => {
        if(res.success){
            fileUtils.downloadBase64(res.value);
        }else{
            Alertas.toastWarning("No se pudo descargar la plantilla")
        }
      })
    }
    else {
      Alertas.toastError("Seleccione los empleados para generar el informe");
    }

    return
  


  } 

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
  } 
  const [filterText, setFilterText] = React.useState('');
  
  const exportar = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Exportar a Excel
    </Tooltip>
  );

	const subHeaderComponentMemo = React.useMemo(() => {
		return (
      <>
        <div className="row flex-1 mt-3 "> 
        
          <div className="col col-md-6 d-flex d-gap-30">
            <Form.Control style={{ width: "250px" }}
              onChange={e => setFilterText(e.target.value)}
              placeholder="Buscar"
            /> 
          </div> 

          <div className="col col-md-4 d-flex d-gap-30 flex-1 justify-content-end  ml-auto">
            <span style={{ lineHeight: "33.5px" }}>{seleccionados} seleccionadas </span>
            <OverlayTrigger delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={exportar}
              transition={false} >
              <button className="btn btn-success" onClick={descargarInforme} style={{width: "30px", padding: "0"}}>
                <i class="fas fa-file-excel"></i> 
              </button>
            </OverlayTrigger> 
          </div>
 
        </div>

        <hr className="w-100" />   

      </>
		);
	}, [filterText, seleccionados]);

  function filtar(){
    return data.filter(x => x.identificacion.includes(filterText) 
        || removeAccents(x.nombre).includes(filterText.toLocaleLowerCase())
    )  
  }

  const SelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setSeleccionados(selectedRows.length)
  };

  return (
    <>  
      <div className="mb-5">
        {data  
          ? <DataTable columns={columns}
                       subHeader
                       subHeaderComponent={subHeaderComponentMemo}
                       data={filterText ? filtar() : data} 
                       pagination
                       selectableRows
                       striped 
                       conditionalRowStyles={conditionalRowStyles}
                       selectableRowDisabled={rowSelectCritera}
                       onSelectedRowsChange={handleChange} /> 
                       
          : <Loader />}
      </div>
    </>
  );
};
