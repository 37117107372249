/*------------------- Peticion para los Selects --------------------*/

import { useState, useEffect } from "react";
import { helpHttp } from "../Recursos/helpHttp";
var api = helpHttp();

export const useFetch = (endpoint) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      setLoading(true);
      const res = await api.get(endpoint);
      if (res.success) {
        setData(res);
        setLoading(false);
      }
    };
    fetchData();
    return () => abortController.abort();
  }, [endpoint]);

  return { data, loading };
};
