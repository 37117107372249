

function downloadBase64(archivo64){
    var bytes = base64ToArrayBuffer(archivo64.data);
    saveByteArray(bytes, archivo64);
}

function saveByteArray(bytes, archivo) {
    var blob = new Blob([bytes], { type: archivo.tipo });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = archivo.nombre;
    link.click();
};

function base64ToArrayBuffer(base64) {
    var binaryString = window.atob(base64);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes;
}


export default { saveByteArray, base64ToArrayBuffer, downloadBase64 }


