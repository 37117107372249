import React, { useState, useEffect } from "react";
import { Form, Button, Col, Badge, Alert } from "react-bootstrap";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { InputEditSelect } from "../../Componentes/Input/InputEditSelect";
import { useHistory } from "react-router";
import { DatalistEdit } from "../../Componentes/Input/DatalistEdit";
import { InputEdit } from "../../Componentes/Input/InputEdit";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from "../../Recursos/Alertas";
/*------------------------------------------Valores iniciales-------------------------------------- */

const initailForm = {
  //Agregar Empresa
  nit: "",
  dv: "",
  direccion: "",
  razonSocial: "",
  municipioId: "",
  tipoPersonaId: "",
  telefonos: "",
  correos: "",
  /* ---------------------------------------- */
  //Representante Legal
  id: "",
  primerNombre: "",
  otrosNombres: "",
  primerApellido: "",
  segundoApellido: "",
  tipoDocumento: "",
  identificacion: "",
  municipio: "",
  tipoPersona: "",
  correo: "",
  contacto: "",
};

export const EditEmpresa = () => {
  const [form, setForm] = useState(initailForm);
  const history = useHistory();

  let api = helpHttp();

  useEffect(() => {
    const datos = async () => {
      var res = await api.get("Empresa");
      if (res.success) {
        var map = mapDto(res.value);
        setForm(map);
      
      }
    };
    datos();
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const mapDto = (dto) => {
    return {
      //Agregar Empresa
      nit: dto.nit,
      dv: dto.dv,
      direccion: dto.direccion,
      razonSocial: dto.razonSocial,
      municipioId: dto.municipioId,
      tipoPersonaId: dto.tipoPersonaId,
      telefonos: dto.telefonos[0] ===null ? "sin telefono" : dto.telefonos[0],
      correos: dto.correos[0] ===null ? "sin correo" : dto.correos[0],
      /* ---------------------------------------- */
      //Representante Legal
      id: dto.representanteLegal.id,
      primerNombre: dto.representanteLegal.primerNombre,
      otrosNombres: dto.representanteLegal.otrosNombres,
      primerApellido: dto.representanteLegal.primerApellido,
      segundoApellido: dto.representanteLegal.segundoApellido,
      tipoDocumento: dto.representanteLegal.tipoDocumento,
      identificacion: dto.representanteLegal.identificacion,
      municipio: dto.representanteLegal.municipioId,
      tipoPersona: dto.representanteLegal.tipoPersona,
      // correo: dto.representanteLegal.correo[0] ===null ? "sin correo" : dto.representanteLegal.correo[0],
      contacto: dto.representanteLegal.contacto,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var data = {
      nit: form.nit,
      direccion: form.direccion,
      dv: form.dv,
      razonSocial: form.razonSocial,
      municipioId: parseInt(form.municipioId.substring(0, 4)),
      tipoPersonaId: form.tipoPersonaId,
      representanteLegal: {
        id: form.id,
        identificacion: form.identificacion,
        primerNombre: form.primerNombre,
        otrosNombres: form.otrosNombres,
        primerApellido: form.primerApellido,
        segundoApellido: form.segundoApellido,
        correo: form.correo,
        tipoPersona: form.tipoPersona,
        municipioId: form.municipio,
        tipoDocumento: form.tipoDocumento,
        contacto: [form.contacto],
      },
      correos: [form.correos],
      telefonos: [form.telefonos],
    };


    let options= {
      body: data,
      headers: { "content-type": "application/json" },
    };

    
    var res = await api.put('Empresa', options);
    if(res.success){
      Alertas.toastSuccess("Cambios guardados")
      // history.push("/Home")
    }


  };
  return (
    <>
      <Alert variant="warning">
        Los campos marcados con <span style={{ color: "red" }}>*</span> son
        obligatorios
      </Alert>
      <hr />
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          {/* ----Nit -------*/}
          <Col lg="3">
            <InputEdit
              disabled="disabled"
              label="Nit"
              type="number"
              placeholder="8694753122"
              name="nit"
              value={form.nit}
              handleChange={handleChange}
            />
          </Col>
          {/* ----Digito De verificacion------ */}
          <Col md="3">
            <InputEdit
              disabled="disabled"
              label="Digito De Verificación"
              type="text"
              placeholder="7"
              name="dv"
              value={form.dv}
              handleChange={handleChange}
              id="dv"
            />
          </Col>
          {/* ----Direccion------ */}
          <Col md="3">
            <InputEdit
              label="Dirección"
              type="text"
              placeholder="Cl. 67 # 24-07"
              name="direccion"
              value={form.direccion}
              handleChange={handleChange}
              id="direccion"
            />
          </Col>
          {/* ----Razon Social------ */}
          <Col lg="3">
            <InputEdit
              label="Razon Social"
              type="text"
              placeholder="Grupo APL"
              name="razonSocial"
              value={form.razonSocial}
              handleChange={handleChange}
              id="rsocial"
            />
          </Col>
        </Form.Row>
        <Form.Row className="separador">
          {/* ----Municipio donde se ubica la empresa ------ */}
          <Col md="4">
            <DatalistEdit
              label="Municipio De La Empresa"
              name="municipioId"
              valorInicial={form.municipioId}
              handleChange={handleChange}
              id="municipioE"
              placeholder="Escriba el municipio donde se encuentra la empresa"
              url="Ubicacion/Municipios"
            />
          </Col>
          {/* ----Tipo de persona ------ */}
          <Col md="4">
            <InputEditSelect
              label="Tipo De Persona"
              name="tipoPersonaId"
              value={form.tipoPersonaId}
              handleChange={handleChange}
              url="Persona/TipoPersona"
              id="tipersonaE"
            />
          </Col>
          {/* ----Telefono Empresa ------ */}
          <Col>
            <InputEdit
              label="Telefono Empresa"
              type="number"
              placeholder="8649523"
              name="telefonos"
              value={form.telefonos}
              handleChange={handleChange}
            />
          </Col>
        </Form.Row>
        <Form.Row className="separador justify-content-md-start">
          <Col md="4">
            <InputEdit
              label="Correo de la empresa"
              type="text"
              placeholder="empresa@gmail.com"
              name="correos"
              value={form.correos}
              handleChange={handleChange}
            />
          </Col>
        </Form.Row>
        <hr style={{ marginTop: "80px" }} />
        {/* --------Representate Legal------------------------------------- */}
        <h1>
          <Badge className="tituloPrincipal" variant="light">
            REPRESENTANTE LEGAL
          </Badge>
        </h1>
        <Form.Row>
          <Col md="4">
            <InputEdit
              type="text"
              placeholder="Primer nombre del empleado"
              name="primerNombre"
              handleChange={handleChange}
              value={form.primerNombre}
              label="Primer Nombre"
            />
          </Col>
          <Col md="4">
            <InputEdit
              type="text"
              placeholder="Otros nombres"
              name="otrosNombres"
              handleChange={handleChange}
              value={form.otrosNombres}
              label="Otros Nombres"
            />
          </Col>
          <Col md="4">
            <InputEdit
              type="text"
              placeholder="Primer apellido del empleado"
              name="primerApellido"
              handleChange={handleChange}
              value={form.primerApellido}
              label="Primer Apellido"
            />
          </Col> 
          <Col>
            <InputEdit
              type="text"
              placeholder="Segundo apellido del empleado"
              name="segundoApellido"
              handleChange={handleChange}
              value={form.segundoApellido}
              label="Segundo Apellido"
            />
          </Col>
          <Col md="4">
            <InputSelect
              name="tipoDocumento"
              values={form.tipoDocumento}
              handleChange={handleChange}
              label="Tipo De Documento"
              url="Persona/TipoDocumento"
            />
          </Col>
          <Col md="4">
            <InputEdit
              type="number"
              placeholder="12354668752"
              name="identificacion"
              value={form.identificacion}
              handleChange={handleChange}
              label="Numero De Documento"
            />
          </Col> 
          {/* <Col md="4">
            <InputSelect
              name="municipio"
              values={form.municipio}
              handleChange={handleChange}
              label="Municipio De Expedicion"
              placeholder="Municipio de expedicion del documento"
              url="Ubicacion/Municipios"
            />
          </Col> */}
          <Col md="4">
            <InputEditSelect
              name="tipoPersona"
              label="Tipo De Persona"
              value={form.tipoPersona}
              handleChange={handleChange}
              url="Persona/TipoPersona"
            />
          </Col>
         {/*  <Col>
              <InputEdit
                type="text"
                placeholder="ejemplo@correo.com"
                name="correo"
                value={form.correo}
                handleChange={handleChange}
                label="Correo"
              />
            </Col> */}
        </Form.Row>
       {/*  <Form.Row className="separador">
            <Col md="4">
              <InputEdit
                type="number"
                placeholder="3156449988"
                name="contacto"
                value={form.contacto}
                handleChange={handleChange}
                label="Telefono"
              />
            </Col>
          </Form.Row> */}
        <hr style={{ marginTop: "80px" }} />

        <Alert variant="warning">
          Los campos marcados con <span style={{ color: "red" }}>*</span> son
          obligatorios
        </Alert>
        <Button
          type="reset"
          onClick={() => {
            history.push("/Home");
          }}
          size="lg"
          className="btn-verde separador"
          style={{ marginRight: "10px" }}
          variant="success"
        >
          Regresar
        </Button>
        <Button size="lg" className="btn-azul separador" type="submit">
          Guardar
        </Button>
        {/* Mensaje de envio correcto */}
      </Form>
    </>
  );
};
