import { React, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from "../../Recursos/Alertas";
import { TablaEmpleados } from "./TablaEmpleados";
import { Badge } from "react-bootstrap";
import { Empleado } from "../Empleado/Empleado";
import { EditEmpleado } from "./EditEmpleado";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./ListaEmpleado.css";
import { Loader } from "../../Recursos/Loader";
import Swal from "sweetalert2";

const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
const weekDays = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];

export const ListaEmpleado = () => {
  const [dataToEdit, setDataToEdit] = useState({ personaId: 0, contratoId: 0 });
  const [enPeticion, setEnPeticion] = useState(false);
  let api = helpHttp();

  /* ------------------------------------------------------------------- Funcion Crear--------------------------------------------------- */
  const mensajeCredo = async (data) => {
    Alertas.toastSuccess("Empleado correctamente");
  };

  /*-------------------------------------------------------------------- Genera Nomina --------------------------------------------------- */
  const [selectedRows, setSelectedRows] = useState([]);
  const [fechaRango, setFechaRango] = useState(null);
  const [fechaPago, setFechaPago] = useState(null);
  const [notas, setNotas] = useState("");
  const [diasTrabajados, setDiasTrabajados] = useState(30);

  const handleGenerarNomina = async () => {
    Swal.fire({
      title: "¿Desea generar una nueva nómina?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#007bff",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sí, Generar Nómina",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (
          fechaRango != null &&
          fechaPago != null &&
          fechaRango.length === 2 &&
          selectedRows.length > 0
        ) {
          setEnPeticion(true);
          var nominas = {
            fechaLiquidacionInicio: new Date(
              fechaRango[0].format("MM/DD/YYYY")
            ),
            fechaLiquidacionFin: new Date(fechaRango[1].format("MM/DD/YYYY")),
            fechaPago: new Date(fechaPago.format("MM/DD/YYYY")),
            diasTrabajados: diasTrabajados,
            notas: notas,
            contratos: selectedRows.map((x) => x.contratoId),
          };

          let options = {
            body: nominas,
            headers: { "content-type": "application/json" },
          };

          
          await api.post("Nomina/Individual/Crear/Lote", options)
           .then(res => {
              console.log(res);
              if (res.success) {
                Alertas.toastSuccess("Nomina generada correctamente");
              }
              else {
                console.log(res.errors);
                var err = res.errors
                  .map(x => x.message)
                  .join('<br><hr>');

                console.log(err);

                Swal.fire({
                  title: "Error al generar la nomina",
                  html: `
                  <div style="text-align: start;">
                    ${err}
                  </div>
                  `,
                  width: 900,
                  icon: "error", 
                  confirmButtonColor: "#007bff",
                  cancelButtonColor: "#d33", 
                  confirmButtonText: "Aceptar",
                })
                
              }
           })
           .catch(err => {
            
           });
 
          setEnPeticion(false);
        } else {
          var msg =
            selectedRows.length > 0
              ? fechaPago ===null
                ? "Ingrese una fecha de pago"
                : "Revise la fecha de la nomina a generar"
              : "No se ha seleccionado ningun empleado";
          Alertas.toastInfo(msg);
        }
      }
    });
  };

  return (
    <>
      <div className="grid-container">
        <Switch>
          {/*---------------------------------------------------------- Nuevo empleado---------------------------------------------------- */}
          <Route exact path="/Home/Lista/Empleados/new">
            <header className="titulo">
              <h1>
                <Badge className="tituloPrincipal" variant="light">
                  REGISTRO EMPLEADO
                </Badge>
              </h1>
            </header>
            <section className="render">
              <Empleado mensajeCredo={mensajeCredo} />
            </section>
          </Route>
          {/* -----------------------------------------------------------Lista de empleados ----------------------------------------------- */}
          <Route exact path="/Home/Lista/Empleados">
            <header className="titulo">
              <h1>
                <Badge className="tituloPrincipal" variant="light">
                  LISTA EMPLEADOS
                </Badge>
              </h1>
              <hr></hr>
            </header>

            <section className="render">
              <Form className="mb-2">
                <div className="row">
                  <div className="col-7">
                    <div className="row">
                      <div className="col-5">
                        <Row style={{ marginLeft: "0px" }}>
                          <Form.Label>Rango de NÓMINA</Form.Label>
                        </Row>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            format="DD/MM/YYYY"
                            range
                            months={months}
                            weekDays={weekDays}
                            render={(value, openCalendar) => {
                              return (
                                <div
                                  className="input-group "
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                                    value={value}
                                    onClick={openCalendar}
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      onClick={openCalendar}
                                    >
                                      &#x1f4c5;
                                    </span>
                                  </div>
                                </div>
                              );
                            }}
                            plugins={[<DatePanel />]}
                            //onChange={ fechaChange(e) }
                            value={fechaRango}
                            onChange={setFechaRango}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <Row style={{ marginLeft: "0px" }}>
                          <Form.Label>Dias Trabajados</Form.Label>
                        </Row>
                        <Form.Control
                          className="input"
                          value={diasTrabajados}
                          onChange={(e) => setDiasTrabajados(e.target.value)}
                        />
                      </div>
                      <div className="col-4">
                        <Row style={{ marginLeft: "0px" }}>
                          <Form.Label>Fecha Pago</Form.Label>
                        </Row>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            format="DD/MM/YYYY"
                            months={months}
                            weekDays={weekDays}
                            render={(value, openCalendar) => {
                              return (
                                <div
                                  className="input-group "
                                  style={{ cursor: "pointer" }}
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="dd/mm/aaaa"
                                    value={value}
                                    onClick={openCalendar}
                                    readOnly
                                  />
                                  <div className="input-group-append">
                                    <span
                                      className="input-group-text"
                                      onClick={openCalendar}
                                    >
                                      &#x1f4c5;
                                    </span>
                                  </div>
                                </div>
                              );
                            }}
                            value={fechaPago}
                            onChange={setFechaPago}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <Row style={{ marginLeft: "0px" }}>
                      <Form.Label>Observaciones a la NÓMINA</Form.Label>
                    </Row>

                    <Form.Control
                      className="input"
                      as="textarea"
                      value={notas}
                      onChange={(e) => setNotas(e.target.value)}
                      rows={3}
                    />
                  </div>
                </div>
                {enPeticion ? (
                  <Loader />
                ) : (
                  <div className="row justify-content-end mt-3">
                    <Col md="auto">
                      <button
                        className="btn btn-success btn-verde"
                        type="button"
                        onClick={handleGenerarNomina}
                      >
                        &#9546;&ensp;&ensp; Generar NÓMINA
                      </button>
                    </Col>
                    <Col md="auto">
                      <Link to="/Home/Lista/Empleados/new">
                        <Button className="btn-azul">Nuevo Empleado</Button>
                      </Link>
                    </Col>
                  </div>
                )}
              </Form>

              <hr />

              <TablaEmpleados
                setDataToEdit={setDataToEdit}
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
              />
            </section>
          </Route>
          {/*---------------------------------------------------------- Editar Empleado ------------------------------------------------------- */}
          <Route exact path="/Home/Lista/Empleados/editar/:id">
            <header className="titulo">
              <h1>
                <Badge className="tituloPrincipal" variant="light">
                  EDITAR EMPLEADO
                </Badge>
              </h1>
            </header>
            <section className="render">
              <EditEmpleado
                personaId={dataToEdit.personaId}
                contratoId={dataToEdit.contratoId}
              />
            </section>
          </Route>
        </Switch>
      </div>
    </>
  );
};
