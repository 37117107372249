import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Menu.css";
import Logo from "../../Recursos/Img/Fabrica_software.png";
import { useHistory } from "react-router";
import { helpHttp } from "../../Recursos/helpHttp";
const api = helpHttp();
 
export const Menu = () => {
  const [usuario, setUsuario] = useState("");
  const history = useHistory();
  const [version, setVersion] = useState("v0.0.0.0");

  //Menu Acordeon
  useEffect(() => {

    setUsuario(JSON.parse(localStorage.getItem('user')))
   
    const btnlateral = document.querySelector("#sidebarToggle");
    const ul = document.querySelector("#accordionSidebar");
    btnlateral.addEventListener("click", () => {
      ul.classList.toggle("toggled");
    }); 

    const getVersion = async () => {
    
      const res = await api.get('Version');
      if (res.success) {
        setVersion(res.value)
      }
    };
    getVersion()

  },[localStorage.getItem('user')]); 


  

  return (
    <>
      <ul
        className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
        id="accordionSidebar"
      >
        <Link
          
          to="/Home"
        >
          <div className="sidebar-brand d-flex align-items-center justify-content-center">
            <img src={Logo} width="130"></img>
          </div>
          <div className="version">
            <span>{version}</span>
          </div>
        </Link>
 
        <hr className="sidebar-divider mt-3" />

        <div style={{ fontSize: "12px" }} className="sidebar-heading">
          Menú
        </div>
        {usuario.tipoUsuario === 2 && (
          <li className="nav-item">
            <Link className="nav-link collapsed" to={"/Home/Lista/Empleados"}>
              <i className="fas fa-fw fas fa-portrait"></i>
              <span style={{ fontSize: "12px" }}>EMPLEADOS</span>
            </Link>
          </li>
        )}

        <li className="nav-item">
          <Link
            className="nav-link collapsed"
            data-toggle="collapse"
            data-target="#collapseUtilities"
            aria-expanded="true"
            aria-controls="collapseUtilities"
            to="#"
          >
            <i className="fas fa-fw far fa-building"></i>
            <span style={{ fontSize: "12px" }}>EMPRESA</span>
          </Link>
          <div
            id="collapseUtilities"
            className="collapse"
            aria-labelledby="headingUtilities"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              {usuario.tipoUsuario === 1 && (
                <Link className="collapse-item" to={"/Home/Empresa/Agregar"}>
                  Agregar Empresa
                </Link>
              )}

              {usuario.tipoUsuario === 2 && (
                <Link className="collapse-item" to={"/Home/Empresa/Verdatos"}>
                  Información
                </Link>
              )}

              {usuario.tipoUsuario === 2 && (
                <Link className="collapse-item" to={"/Home/Empresa/Config"}>
                  Configuración
                </Link>
              )}
            </div>
          </div>
        </li>
        {usuario.tipoUsuario === 2 && (
          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              data-toggle="collapse"
              data-target="#collapsetilities"
              aria-expanded="true"
              aria-controls="collapsetilities"
              to="#"
            >
              <i className="fas fa-fw fa-file-alt"></i>
              <span style={{ fontSize: "12px" }}>NÓMINA</span>
            </Link>
            <div
              id="collapsetilities"
              className="collapse"
              aria-labelledby="headingtilities"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                {usuario.tipoUsuario === 2 && (
                  <Link
                    className="collapse-item"
                    to={"/Home/Nominas/Individual/Lista"}
                  >
                    Individual
                  </Link>
                )}

                {usuario.tipoUsuario === 2 && (
                  <Link
                    className="collapse-item"
                    to={"/Home/Nominas/Ajuste/Lista"}
                  >
                    Ajuste
                  </Link>
                )}
                {usuario.tipoUsuario === 2 && (
                  <Link
                    className="collapse-item"
                    to={"/Home/Nominas/Eliminada/Lista"}
                  >
                    Eliminada
                  </Link>
                )}
              </div>
            </div>
          </li>
        )}

        {usuario.tipoUsuario === 2 && usuario.tipoAmbiente === 2 &&
          (<li className="nav-item">
            <Link
              className="nav-link collapsed"
              to={"/Home/Habilitacion"}
            >
              <i className="fas fa-fw fa-clipboard-check"></i>
              <span>HABILITACIÓN</span>
            </Link>
          </li>
        )}

          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              to={"/Home/carge-plantilla-empleados"}
            >
              <i className="fas fa-fw fa-file-upload"></i>
              <span>PLANTILLA EMPLEADOS</span>
            </Link>
          </li>
        


          <li className="nav-item">
            <Link
              className="nav-link collapsed"
              to={"/Home/Usuario"}
            >
              <i className="fas fa-fw fa-user"></i>
              <span>USUARIO</span>
            </Link>
          </li>

        <hr className="sidebar-divider" />

        <div className="text-center  d-md-inline">
          <button
            className="rounded-circle  border-0"
            id="sidebarToggle"
          ></button>
        </div>
      </ul>
    </>
  );
};
