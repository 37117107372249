import React, { useState, useEffect } from "react";
import { Badge, Form, Col, Button, Alert } from "react-bootstrap";
import { InputEdit } from "../../Componentes/Input/InputEdit";
import { useHistory } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import Alertas from "../../Recursos/Alertas";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import "./Configuracion.css";
import nitUtils from '../../Utilidades/nitUtils'

const initialForm = {
  softwareSC: "",
  softwareID: "",
  periodoNominaId: "",
  ambienteId: "", 
  rutaCertificado: "",
  claveCertificado: "",
  pin: "",
};

export const AggConfiguracion = ({ data, createData }) => {
  const [config, setConfig] = useState(initialForm);
  let history = useHistory();

  useEffect(() => {
    if (data) {  
      setConfig(data);
    }
  }, [data]);

  const handleChange = (e) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value,
    }); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    if ( 
      !config.softwareSC ||
      !config.softwareID ||
      !config.periodoNominaId ||
      !config.ambienteId ||
      !config.rutaCertificado ||
      !config.claveCertificado ||
      !config.pin
    ) {
      Alertas.toastError("Campos obligatorios sin rellenar");
      return;
    }

    config.certificadoBase64 = await readFileDataAsBase64();
    createData(config);
    
  };

  function readFileDataAsBase64() {
    const file = document.getElementById("file-certificado").files[0];

    if(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
  
        reader.onload = (event) => {
          resolve(event.target.result);
        };
  
        reader.onerror = (err) => {
          reject(err);
        };
  
        reader.readAsDataURL(file);
      });
    }

  }

  const handleReset = (e) => {
    setConfig(initialForm);
    history.push("/");
  };

  return (
    <div className="grid-container">
      <header className="titulo">
        <h1>
          <Badge className="tituloPrincipal" variant="light">
            CONFIGURACIÓN
          </Badge>
        </h1>
      </header>
      <section className="render">
        <Alert variant="warning">
          Los campos marcados con <span style={{ color: "red" }}>*</span> son
          obligatorios
        </Alert>
        <hr />
        <Form onSubmit={handleSubmit}>
          <Form.Row> 
            <Col>
              <InputEdit
               className="input"
                type="text"
                placeholder=""
                name="softwareSC"
                value={config.softwareSC}
                handleChange={handleChange}
                label="softwareSC"
              />
            </Col>
            <Col>
              <InputEdit
               className="input"
                type="text"
                placeholder=""
                name="softwareID"
                value={config.softwareID}
                handleChange={handleChange}
                label="softwareID"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col>
              <InputSelect
                label="Periodo De NÓMINA"
                name="periodoNominaId"
                values={config.periodoNominaId}
                handleChange={handleChange}
                url="Nomina/Periodo"
              />
            </Col>
            <Col>
              <InputSelect
                label="Ambiente"
                name="ambienteId"
                values={config.ambienteId}
                handleChange={handleChange}
                url="Nomina/Ambiente"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col>
              <InputEdit
                className="configp input"
                type="file"
                name="rutaCertificado"
                placeholder="rutaCertificado"
                //value={config.rutaCertificado}
                handleChange={handleChange}
                label="Ruta Certificado"
                id="file-certificado"
              />
            </Col>
            <Col>
              <InputEdit
                type="text"
                className="input"
                name="claveCertificado"
                placeholder="claveCertificado"
                value={config.claveCertificado}
                handleChange={handleChange}
                label="Clave Certificado"
              />
            </Col>
            <Col>
              <InputEdit
                className="input"
                type="text"
                name="pin"
                placeholder="pin"
                value={config.pin}
                handleChange={handleChange}
                label="Pin"
              />
            </Col>
          </Form.Row>

          <hr style={{ marginTop: "80px" }} />

          <Alert variant="warning">
            Los campos marcados con <span style={{ color: "red" }}>*</span> son
            obligatorios
          </Alert>
          <Button  className="btn-azul" type="submit">Guardar</Button>
        </Form>
      </section>
    </div>
  );
};
