
import React from 'react';
import { Button } from 'react-bootstrap';
import Alertas from "../../Recursos/Alertas";
import { helpHttp } from '../../Recursos/helpHttp'; 

const Habilitacion = () => {

    let api = helpHttp(); 
  
    const generarNominasIndividuales =  async () => {

        var res = await api.post('Habilitacion/NominaIndividual')

        if(res.success){
            Alertas.toastSuccess("Nominas de prueba para la habilitacion generadas correctamente")
        }

    }

    const generarNominasDeAjuste =  async () => {

        var res = await api.post('Habilitacion/NominaDeAjuste')

        if(res.success){
            Alertas.toastSuccess("Nominas de prueba para la habilitacion generadas correctamente")
        }

    }

    const EliminarDatosHabilitacion =  async () => {

        var res = await api.del('Habilitacion')

        if(res.success){
            Alertas.toastSuccess("Datos de habilitacion elimidados correctamente correctamente")
        }

    }

    return (
        <div>
           <Button variant="success" className="mr-3 btn-verde " onClick={generarNominasIndividuales}>Generar Nominas Individuales</Button>
           <Button className="btn-verde mr-3 " variant="success" onClick={generarNominasDeAjuste}>Generar Nominas De Ajuste</Button>
           <button className="btn btn-danger" onClick={EliminarDatosHabilitacion}>Eliminar Datos de habilitacion</button>
        </div>
    );
}

export default Habilitacion;
  















