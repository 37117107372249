import React, { useState, useEffect } from "react";
import { Form, Col, Button, Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import { InputEdit } from "../../Componentes/Input/InputEdit";
import { InputEditSelect } from "../../Componentes/Input/InputEditSelect";
import { helpHttp } from "../../Recursos/helpHttp";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { DevengadosDeducciones } from "../../Componentes/DevengadosDeducciones/DevengadosDeducciones";
import Alertas from "../../Recursos/Alertas";
import Swal from 'sweetalert2'; 

/* --------------------------------Valores iniciales-------------------------------- */
const initailForm = {
  identificacion: "",
  primerNombre: "",
  otrosNombres: "",
  primerApellido: "",
  segundoApellido: "",
  correo: "",
  tipoPersona: 1,
  tipoDocumento: 11,
  telefono: "",

  altoRiesgoPension: true,
  fechaIngreso: "",
  fechaRetiro: "",
  salarioIntegral: true,
  lugarTrabajoDireccion: "",
  lugarTrabajoMunicipioId: 0,
  subTipoTrabajadorId: 0,
  tipoTrabajadorId: 0,
  tipoContrato: 1,
  prefijoId: 0,
  sueldo: 0,

  nominaId: 0,
  tipoCuentaId: 0,
  medioPagoId: 0,
  bancoId: 0,
  numeroCuenta: "",
  fechaPago: "",
  formaPagoId: 0,

  devengados: [],
  deducciones: [],
};

export const EditEmpleado = ({ personaId, contratoId }) => {
  const [form, setForm] = useState(initailForm);
  const [liquidacion, setLiquidacion] = useState(false);
  const [detallePago, setDetallePago] = useState(false);
  const [devengados, setdevengados] = useState([{ value: 0, label: "Seleccione ..." },]);
  const [deducciones, setdeducciones] = useState([
    { value: 0, label: "Seleccione ..." },
  ]);

  function ocultarMostrarFecha() {
    setLiquidacion(!liquidacion);
  }

  let history = useHistory();
  let api = helpHttp();
  /*----------------------------- Cambia el valor de los campos-----------------------------*/
  useEffect(() => {
    const datos = async () => {
      var res = await api.get(
        `Empleado?personaId=${personaId}&contratoId=${contratoId}`
      );
      if (res.success) {
        var map = mapDto(res.value)
        setForm(map);
        medioPago(map.medioPagoId)
      }
    };

    datos();
  }, []);

  const onChange = (e) => { 

    // console.log(e.target);
    // console.log(e.target.name);
    // console.log(e.target.value);
 
    setForm({...form, [e.target.name]: e.target.value });

    if (e.target.name === 'medioPagoId') {
      medioPago(e.target.value)
    }
  };

  const numeroKeyDown = (e) => {
    const regexNumero = /[^0-9]+/gm //numero
    const regexNav = /(Backspace|ArrowLeft|ArrowRight)/ //navegacion
  
    !regexNav.test(e.key) && regexNumero.test(e.key) && e.preventDefault();
  }

  const mapDto = (dto) => {

    var retir = dto.contrato.fechaRetiro === null ? false : true;
    setLiquidacion(retir)

    return {
      identificacion: dto.persona.identificacion,
      primerNombre: dto.persona.primerNombre,
      otrosNombres: dto.persona.otrosNombres,
      primerApellido: dto.persona.primerApellido,
      segundoApellido: dto.persona.segundoApellido,
      correo: dto.persona.correo,
      tipoPersona: dto.persona.tipoPersona,
      municipioId: dto.contrato.lugarTrabajoMunicipioId,
      tipoDocumento: dto.persona.tipoDocumento,
      telefono: "",

      altoRiesgoPension: dto.contrato.altoRiesgoPension ? "Si" : "No",
      fechaIngreso: dto.contrato.fechaIngreso.substring(0, 10),
      fechaRetiro: dto.contrato.fechaRetiro === null ? null : dto.contrato.fechaRetiro.substring(0, 10),
      salarioIntegral: dto.contrato.salarioIntegral ? "Si" : "No",
      lugarTrabajoDireccion: dto.contrato.lugarTrabajoDireccion,
      lugarTrabajoMunicipioId: dto.contrato.lugarTrabajoMunicipioId,
      subTipoTrabajadorId: dto.contrato.subTipoTrabajadorId,
      tipoTrabajadorId: dto.contrato.tipoTrabajadorId,
      tipoContrato: dto.contrato.tipoContrato,
      prefijoId: dto.contrato.prefijoId,
      sueldo: dto.contrato.sueldo,

      nominaId: dto.pago.nominaId,
      tipoCuentaId: dto.pago.tipoCuentaId,
      medioPagoId: dto.pago.medioPagoId,
      bancoId: dto.pago.bancoId,
      numeroCuenta: dto.pago.numeroCuenta,
      fechaPago: dto.pago.fechaPago,
      formaPagoId: dto.pago.formaPagoId,

      devengados: dto.devengados,
      deducciones: dto.deducciones,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (devengados.length ===0) {
      Alertas.toastWarning("No se han asignado devengados al empleado")
      return
    }

    var data = {
      persona: {
        id: personaId,
        identificacion: form.identificacion,
        primerNombre: form.primerNombre,
        otrosNombres: form.otrosNombres,
        primerApellido: form.primerApellido,
        segundoApellido: form.segundoApellido,
        correo: form.correo,
        tipoPersona: parseInt(form.tipoPersona),
        municipioId: parseInt(form.lugarTrabajoMunicipioId),
        tipoDocumento: parseInt(form.tipoDocumento),
      },

      contrato: {
        id: contratoId,
        altoRiesgoPension: form.altoRiesgoPension === "Si" ? true : false,
        fechaIngreso: form.fechaIngreso,
        fechaRetiro: `${form.fechaRetiro}` === '' || `${form.fechaRetiro}` ==='null' || !liquidacion ? null : `${form.fechaRetiro}`,
        salarioIntegral: form.salarioIntegral === "Si" ? true : false,
        lugarTrabajoDireccion: form.lugarTrabajoDireccion,
        lugarTrabajoMunicipioId: parseInt(form.lugarTrabajoMunicipioId),
        subTipoTrabajadorId: parseInt(form.subTipoTrabajadorId),
        tipoTrabajadorId: parseInt(form.tipoTrabajadorId),
        tipoContrato: parseInt(form.tipoContrato),
        // prefijoId: form.prefijoId,
        sueldo: form.sueldo,
      },

      pago: {
        nominaId: form.nominaId,
        tipoCuentaId: parseInt(form.tipoCuentaId),
        medioPagoId: parseInt(form.medioPagoId),
        bancoId: parseInt(form.bancoId),
        numeroCuenta: form.numeroCuenta,
        fechaPago: form.fechaPago,
        formaPagoId: 1,
      },

      devengados: devengados.filter(x => x.tipo !== undefined).map(function (x) {
        return {
          pago: x.pago,
          cantidad: x.cantidad,
          fechaInicio: x.fechaInicio || null,
          fechaFin: x.fechaFin || null,
          tipoDevengadoId: x.tipo,
          id: x.id,
        };
      }),

      deducciones: deducciones.filter(x => x.tipo !== undefined).map(function (x) {
        return {
          pago: x.pago,
          cantidad: x.cantidad,
          fechaInicio: x.fechaInicio || null,
          fechaFin: x.fechaFin || null,
          tipoDeduccionId: x.tipo,
          id: x.id,
        };
      }),
    };

    console.log(data);
    
    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };

    return api.put("Empleado", options)
    .then(res => {
      console.log(res)
      if(res.success){
        Alertas.toastSuccess("Cambios al empleado guardados con exito")
        history.push("/Home/Lista/Empleados")
      }
      else{
        res.errors.shift();
        for (let err of res.errors) {
          Alertas.toastError(err.message);
        }
      }
    })
    .catch(err => {
      console.log(err)
    });

    

  };

  function regresar() {
    Swal.fire({
      title: '¿Los Cambios no se guardarán, está seguro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Cancelar',
      cancelButtonText: `NO, Seguir Editando`,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#dc3545',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        history.push("/Home/Lista/Empleados");
      }
    })
  }

  function medioPago(value) {
    var array  = [ 0, 2, 9, 10, 13, 16, 18, 19, 26, 27, 29, 30, 71 ]
    var ocultar = array.includes(parseInt(value)) 
    setDetallePago(!ocultar)
  };

  return (
    <>
      <Button
        size="lg"
        className="btn btn-light"
        onClick={regresar}
        style={{ display: "flex", textAlign: "start", marginBottom: "5px" }}
      >
        <i className="fas fa-arrow-left"><span className="ml-3">Regresar</span></i>
      </Button>
      <hr />
      <Form onSubmit={handleSubmit}>
        {/* ---------------------------------------------------------INFORMACION PERSONAL-------------------------------------------------------------- */}
        <div>
          <Form.Row>
            <Col lg={4} xl={3}>
              <InputEdit
                type="text"
                placeholder="Primer nombre del empleado"
                name="primerNombre"
                handleChange={onChange}
                value={form.primerNombre}
                label="Primer Nombre"
              />
            </Col>
            <Col lg={4} xl={3}>
              <InputEdit
                type="text"
                placeholder="Otros nombres"
                name="otrosNombres"
                handleChange={onChange}
                value={form.otrosNombres}
                label="Otros Nombres"
              />
            </Col>
            <Col lg={4} xl={3}>
              <InputEdit
                type="text"
                placeholder="Primer apellido del empleado"
                name="primerApellido"
                handleChange={onChange}
                value={form.primerApellido}
                label="Primer Apellido"
              />
            </Col>
            <Col lg={4} xl={3}>
              <InputEdit
                type="text"
                placeholder="Segundo apellido del empleado"
                name="segundoApellido"
                handleChange={onChange}
                value={form.segundoApellido}
                label="Segundo Apellido"
              />
            </Col>
            <Col lg={4} xl={3}>
              <InputSelect
                name="tipoDocumento"
                values={form.tipoDocumento}
                handleChange={onChange}
                label="Tipo De Documento"
                url="Persona/TipoDocumento"
              />
            </Col>
            <Col lg={4} xl={3}>
              <InputEdit
                type="number" 
                name="identificacion"
                value={form.identificacion}
                handleChange={onChange}
                handleKeyDown={numeroKeyDown}
                label="Numero De Documento"
              />
            </Col>
            <Col lg={4} xl={2}>
              <InputEditSelect
                name="tipoPersona"
                label="Tipo De Persona"
                value={form.tipoPersona}
                handleChange={onChange}
                url="Persona/TipoPersona"
              />
            </Col>
            <Col lg={4} xl={4}>
              <InputEdit
                name="correo"
                label="Correo"
                value={form.correo}
                handleChange={onChange}
              />
            </Col>
          </Form.Row>
          <hr style={{ marginTop: "80px" }}></hr>
        </div>
        {/* ---------------------------------------------------------INFORMACION DE CONTRATO------------------------------------------------------- */}
        <div>
          <h4 className="separador title-section"
          >
            INFORMACIÓN DE CONTRATO
          </h4>
          <Form.Row>
            <Col lg={4} xl={4}>
              <InputEdit
                type="date"
                placeholder="Fecha en que ingreso a la empresa"
                name="fechaIngreso"
                value={form.fechaIngreso}
                handleChange={onChange}
                label="Fecha Ingreso"
              />
            </Col>
            <Col lg={4} xl={2}>
              <Form.Label
                style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
              >
                Alto Riesgo<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="input"
                as="select"
                defaultValue="Seleccionar..."
                name="altoRiesgoPension"
                value={form.altoRiesgoPension}
                onChange={onChange}
              >
                <option>Seleccionar....</option>
                <option>Si</option>
                <option>No</option>
              </Form.Control>
            </Col>
            <Col lg={4} xl={6}>
              <InputSelect
                name="lugarTrabajoMunicipioId"
                values={form.lugarTrabajoMunicipioId}
                handleChange={onChange}
                label="Municipio De Trabajo"
                placeholder="Municipio de trabajo"
                url="Ubicacion/Municipios"
              />
            </Col>
            <Col lg={6} xl={4}>
              <InputEdit
                type="text"
                placeholder="Direccion de trabajo"
                name="lugarTrabajoDireccion"
                value={form.lugarTrabajoDireccion}
                handleChange={onChange}
                label="Lugar de trabajo"
              />
            </Col>
            <Col lg={6} xl={4}>
              <InputSelect
                name="tipoTrabajadorId"
                values={form.tipoTrabajadorId}
                handleChange={onChange}
                label="Tipo de trabajador"
                placeholder="Seleccionar..."
                url="Empleado/Tipo"
              />
            </Col>
            <Col lg={6} xl={4}>
              <InputSelect
                name="subTipoTrabajadorId"
                values={form.subTipoTrabajadorId}
                handleChange={onChange}
                label="Sub tipo de trabajador"
                placeholder="Seleccionar..."
                url="Empleado/SubTipo"
              />
            </Col>
            <Col lg={6} xl={4}>
              <InputSelect
                name="tipoContrato"
                values={form.tipoContrato}
                handleChange={onChange}
                label="Tipo de contrato"
                placeholder="Seleccionar..."
                url="Empleado/TipoContrato"
              />
            </Col>
            <Col lg={4} xl={4}>
              <InputEdit
                type="number"
                placeholder="Salario del empleado"
                name="sueldo"
                value={form.sueldo}
                handleChange={onChange}
                label="Sueldo"
              />
            </Col>
            <Col lg={2} xl={2}>
              <Form.Label
                style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
              >
                Salario integral<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="input"
                as="select"
                defaultValue="Seleccionar..."
                name="salarioIntegral"
                value={form.salarioIntegral}
                onChange={onChange}
              >
                <option>Seleccionar</option>
                <option>Si</option>
                <option>No</option>
              </Form.Control>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg={2} xl={2}>
              <div className="form-check mt-5">
                <input className="form-check-input mt-3" type="checkbox" id="defaultCheck1" onClick={ocultarMostrarFecha} 
                checked={liquidacion} />
                <label className="label-input" for="defaultCheck1">
                  Liquidacion
                </label>
              </div>
            </Col>
            {liquidacion ?
              <Col lg={4} xl={4} id="fecha-retiro">
                <Form.Label className="label-input">
                  Fecha de retiro
                </Form.Label>
                <Form.Control
                  className="input"
                  type="date"
                  name="fechaRetiro"
                  value={form.fechaRetiro}
                  onChange={onChange}
                />
              </Col>
              : null}
          </Form.Row>
        </div>
        {/*----------------------------------------------------------PAGO------------------------------------------------- */}
        <div>
          <hr style={{ marginTop: "80px" }} />
          <h4 className="separador title-section"
          >
            MÉTODO DE PAGO
          </h4>
          <Form.Row>
            <Col md="6">
              <InputSelect
                name="medioPagoId"
                values={form.medioPagoId}
                handleChange={onChange}
                label="Metodo de pago"
                url="Pago/Medios_Pago"
              />
            </Col>
            {detallePago 
              ? <Col>
                <InputEditSelect
                  name="bancoId"
                  label="Bancos"
                  value={form.bancoId}
                  handleChange={onChange}
                  url="Pago/Bancos"
                />
              </Col>
              : null
            }
          </Form.Row>
          {detallePago
            ? <Form.Row>
              <Col>
                <InputSelect
                  name="tipoCuentaId"
                  label="Tipo de cuenta"
                  values={form.tipoCuentaId}
                  handleChange={onChange}
                  url="Pago/Tipos_Cuenta"
                />
              </Col>
              <Col>
                <InputEdit
                  type="text"
                  placeholder="Digite el numero de cuenta"
                  name="numeroCuenta"
                  value={form.numeroCuenta}
                  label="Número de cuenta"
                  handleChange={onChange}
                />
              </Col>
            </Form.Row> 
            : null  
          }

        </div>
        {/*--------------------------------------------------------- DEVENGADOS --------------------------------------------------------*/}
        <div>
          <hr style={{ marginTop: "80px" }} />
          <h5 className="separador title-section"
          >
            DEVENGADOS
          </h5>

          <DevengadosDeducciones
            valoresIniciales={form.devengados}
            conceptoValues={devengados}
            setConceptoValues={setdevengados}
            endPoint="Devengado/Tipos"
          />
        </div>
        {/*--------------------------------------------------------- DEDUCCIONES------------------------------------------------ */}
        <div>
          <hr style={{ marginTop: "80px" }} />
          <h5 className="separador title-section"> DEDUCCIONES </h5>
          <DevengadosDeducciones
            valoresIniciales={form.deducciones}
            conceptoValues={deducciones}
            setConceptoValues={setdeducciones}
            endPoint="Deduccion/Tipos"
          />
        </div>
        {/* --------------------------------------------------------------------GUARDAR---------------------------------------------------------------- */}
        <div>

          <hr style={{ marginTop: "80px" }} />
          <Alert variant="warning">
            Los campos marcados con <span style={{ color: "red" }}>*</span> son
            obligatorios
          </Alert>
          <div className="my-5">
            <Button size="lg" className="btn btn-success separador mr-3" type="submit">
              Guardar
            </Button>
            <Button
              size="lg"
              className="btn btn-light"
              onClick={regresar}
              style={{ marginRight: "10px" }}
            >
              Regresar
            </Button>
          </div>
        </div>

      </Form>
    </>
  );
};
