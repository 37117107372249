import React, { useState, useEffect } from "react";
import "./CargePlantillaEmpleados.css";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { Form } from "react-bootstrap";
import Alertas from "../../Recursos/Alertas";
import { helpHttp } from "../../Recursos/helpHttp";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactJson from "react-json-view";
import fileUtils from "../../Utilidades/fileUtils";
import { Spinner } from "./Spinner";

const initialForm = {
  empresaId: 0,
};

export const CargePlantillaEmpleados = (props) => {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [usuario, setUsuario] = useState("");
  const [carge, setCarge] = useState(initialForm);
  const [nombre, setNombre] = useState(
    "Suelte los archivos aquí o haga click para cargar."
  );
  let api = helpHttp();

  useEffect(() => {
    setUsuario(JSON.parse(localStorage.getItem("user")));
  }, [localStorage.getItem("user")]);

  const handleChange = (e) => {
    setCarge({
      ...carge,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const file = document.getElementById("file-plantilla").files[0];

    /*  console.log(usuario.tipoUsuario);
    console.log(usuario.tipoUsuario === 2); */
    if (carge.empresaId != 0 || usuario.tipoUsuario === 2) {
      if (file != undefined) {
        if (
          file.name.search(".xlsx") != -1 &&
          file.name.search(".Xlsx") != -1
        ) {
          Alertas.toastWarning("Elija el tipo de archivo correcto (Xlsx)");
        } else {
          setIsLoading(true);

          carge.plantillaBase64 = await readFileDataAsBase64(file);
          let options = {
            body: carge,
            headers: { "content-type": "application/json" },
          };

          await api.post(
            usuario.tipoUsuario === 1
              ? "CargeArchivos"
              : "CargeArchivos/Empresa",
            options,
            true
          ).then(async res => {
            if (!res.success) {
              await MySwal.fire({
                title: <strong>Respuesta de Nomina APL!</strong>,
                // html: <i>{res.value}</i>,
                width: 850,
                html: (
                  <div style={{ textAlign: "left" }}>
                    {res.errors.map((result) => {
                      var EmpleadoError = JSON.parse(result.message);
                      console.log(EmpleadoError);
                      return (
                        <ul key={EmpleadoError.Empleado}>
                          <strong>{EmpleadoError.Empleado} :</strong>
                          {EmpleadoError.Errores.map((res) => {
                            return (
                              <li key={res}>
                                <p> &nbsp;&nbsp;&nbsp;&nbsp;*{res}</p>
                              </li>
                            );
                          })}
                        </ul>
                      );
                    })}
                    {/* <ReactJson src={res} onAdd={false} onEdit={false} enableClipboard={false}  />  */}
                  </div>
                ),
                confirmButtonColor: "#0A6ED9",
                icon: "info",
              });
            } else {
              Alertas.toastSuccess("Plantilla cargada correctamente");
            }
          })
          .catch(err => {
            console.error(err);
            Alertas.toastError("Error al cargar la plantilla")
          });

          setIsLoading(false);
        }
      } else {
        Alertas.toastWarning("Debe cargar la plantilla con los empleados");
      }
    } else {
      Alertas.toastWarning("Debe seleccionar una empresa");
    }
  };

  async function descargarPlantilla() {
    var res = await await api.post("CargeArchivos/Plantilla");

    if (res.success) {
      fileUtils.downloadBase64(res.value);
    } else {
      Alertas.toastWarning("No se pudo descargar la plantilla");
    }
  }

  function readFileDataAsBase64(file) {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          resolve(event.target.result);
        };

        reader.onerror = (err) => {
          reject(err);
        };

        reader.readAsDataURL(file);
      });
    }
  }

  function onChangeFile(e) {
    e.preventDefault();
    const file = document.getElementById("file-plantilla").files[0];

    if (file) {
      setNombre(file.name);

      if (file.name.search(".xlsx") ===-1 && file.name.search(".Xlsx") ===-1) {
        Alertas.toastWarning("Elija el tipo de archivo correcto (Xlsx)");
      }
    } else {
    }
  }
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <div className="d-flex">
        <button className="btn btn-ligth mt-2" onClick={descargarPlantilla}>
          <i className="fas fa-file-download mr-3"></i> Descargar Plantilla
        </button>
      </div>
      <Form onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-6">
            {usuario.tipoUsuario === 1 && (
              <InputSelect
                label="Empresa"
                name="empresaId"
                values={carge.empresaId}
                handleChange={handleChange}
                url="Empresa/Combo"
              />
            )}

            <div className="dropzone mx-auto justify-content-center mt-3">
              <p className="font-weight-bold">{nombre}</p>
              <img
                src="http://100dayscss.com/codepen/upload.svg"
                height="60%"
              />
              <input
                type="file"
                className="upload-input my-auto"
                id="file-plantilla"
                onChange={onChangeFile}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>

            <div>
              <button className="btn btn-success mt-2">Cargar Plantilla</button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
