
import { useState, useEffect } from "react";
import Select from "react-select";
import { helpHttp } from "../../Recursos/helpHttp";


const api = helpHttp();
 
const select2Styles = {
    
    control: styles => (
        { ...styles, 
            height: 'calc(1.5em + 0.75rem + 2px) !important' ,
            minHeight: 'none !important',
            textAlign: 'start !important', 
        }
    ), 
    option: styles => (
        { ...styles, 
            height: 'calc(1.5em + 0.75rem + 2px) !important' ,
            minHeight: 'none !important',
            textAlign: 'start !important'
        }
    ),

};


export const Select2 = ({ value, selectChange, data }) => {

    const [opciones, setOpciones] = useState([ { label : 'Sin Opciones'}]);
 

    async function cargarOpciones(){

        if(data){
            setOpciones(data)
        }else{
            var res = await api.get('Empleado/Select');
            if (res.success) {
                setOpciones(
                    res.value.map(function (x) {
                    return {
                        label: x.descripcion,
                        value: x.id
                    };
                    })
                );
            }else{
                setOpciones([ { label : 'Sin Opciones', value: 0 }]);
            }
        }


    }
 
    useEffect(()=>{
        cargarOpciones()
    },[]) 

    return<>
        <Select
            className="select2-control"
            options={opciones}
            value={value}
            onChange={selectChange}
            styles={select2Styles}
        />
    </>

}
