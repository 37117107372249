import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Loader } from "../../Recursos/Loader";
import { helpHttp } from "../../Recursos/helpHttp";
let api = helpHttp();

export const Datalist = ({ placeholder ,name, values, handleChange, label, url }) => {

  const [data, setData] = useState([]);
 
  useEffect(() => {
 
    const cargarConfiguracion = async () => {
      var res = await api.get(url)
      if (res.success) {
        setData(res.value);
      }

      if(values){
        var opcion = (data.filter(option => option.id === values))[0];
        if(opcion){
          values = `${opcion.id} ${opcion.descripcion}`
          handleChange({ target: { name: name, value : values }} )
        }
      }
    }
    
    cargarConfiguracion()

  }, [values]);

  return (
    <>
      <Form.Label style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }} >
        {label} <span style={{ color: "red" }}>*</span>
      </Form.Label>

      <Form.Control
        className="input"
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        name={name}
        value={values}
        onChange={handleChange}
        list="municipio"
      />
      <datalist id="municipio">
        {data 
          ? data.map((el) => ( <option value={el.id+ " " + el.descripcion}  key={el.id}/> ))
          : null
        }
      </datalist>
    </>
  );
};
