import React from "react";
import { Form } from "react-bootstrap";
import { Loader } from "../../Recursos/Loader";
import { Message } from "../../Recursos/Message";
import { useFetch } from "../../Recursos/useFetch";

export const InputEditSelect = ({ disabled ,name, value, handleChange, label, url }) => {
  const { data, error, loading } = useFetch(url);

  if (!data) return null;

  if (error) {
    return (
      <Message
        msg={`Error ${error.status}: ${error.statusText}`}
        bgcolor="#dc3545"
      />
    );
  }
  let options = data.value;

  return (
    <>
      <Form.Label
        style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
      >
        {label}
        <span style={{ color: "red" }}>*</span>
      </Form.Label>
      {loading && <Loader />}
      <Form.Control
        disabled={disabled}
        className="input"
        as="select"
        defaultValue="Seleccionar"
        name={name}
        value={value}
        onChange={handleChange}
      >
        <option value={"0"}>Seleccionar...</option>
        {data ?
          options.map((el) => (
            <option value={el.id} key={el.id}>
              {el.descripcion}
            </option>
          ))
          : null
        }
      </Form.Control>
    </>
  );
};
