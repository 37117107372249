import React from "react";
import { Badge } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { TablaNominasAjuste } from "./TablaNominasAjuste";  

export const ListaNominaAjuste = () => {
 
  return (
    <div className="grid-container">
      <Switch>
        <Route exact path="/Home/Nominas/Ajuste/Lista">
          <header className="titulo">
            <h1>
              <Badge className="tituloPrincipal" variant="light">
                NÓMINAS DE AJUSTE ELECTRÓNICAS
              </Badge>
            </h1>
            <hr></hr>
          </header>
          <section className="render mb-5">
            <TablaNominasAjuste  />
          </section>
        </Route>  
      </Switch>
    </div>
  );
};
