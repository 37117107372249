import React, { useState, useEffect } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { helpHttp } from "../../Recursos/helpHttp";
import { InputEdit } from "../../Componentes/Input/InputEdit";
import { InputEditSelect } from "../../Componentes/Input/InputEditSelect";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { DevengadosDeducciones } from "../../Componentes/DevengadosDeducciones/DevengadosDeducciones";
import { Datalist } from "../../Componentes/Input/Datalist";
import { useHistory } from "react-router";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./NominaAjuste.css";
import Alertas from "../../Recursos/Alertas";
import Swal from 'sweetalert2';

const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
const weekDays = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
const initailForm = {
  fechaLiquidacionInicio: new Date(),
  fechaLiquidacionFin: new Date(),
  notas: "",
  periodoNominaId: 0,
  fechaPago: "",

  //empleado
  emp_identificacion: "",
  emp_primerNombre: "",
  emp_otrosNombres: "",
  emp_primerApellido: "",
  emp_segundoApellido: "",
  emp_correo: "",
  emp_tipoPersona: 1,
  emp_municipioId: 0,
  emp_tipoDocumento: 11,
  emp_telefono: "",

  altoRiesgoPension: true,
  fechaIngreso: new Date(),
  fechaRetiro: "",
  salarioIntegral: true,
  lugarTrabajoDireccion: "",
  lugarTrabajoMunicipioId: 0,
  subTipoTrabajadorId: 0,
  tipoTrabajadorId: 0,
  tipoContrato: 1,
  prefijoId: 0,
  sueldo: 0,

  nominaId: 0,
  tipoCuentaId: 0,
  medioPagoId: 0,
  bancoId: 0,
  numeroCuenta: "",
  formaPagoId: 0,

  devengados: [],
  deducciones: [],

  rep_identificacion: "",
  rep_primerNombre: "",
  rep_otrosNombres: "",
  rep_primerApellido: "",
  rep_segundoApellido: "",
  rep_correo: "",
  rep_tipoPersona: 1,
  rep_municipioId: 0,
  rep_tipoDocumento: 11,
  rep_telefono: "",
};

export const Nominajuste = ({ nominaId }) => {

  const [form, setForm] = useState(initailForm);
  const [liquidacion, setLiquidacion] = useState(false);
  const [fechaPago, setFechaPago] = useState(null);
  const [devengados, setdevengados] = useState([{ value: 0, label: "Seleccione ..." }]);
  const [deducciones, setdeducciones] = useState([{ value: 0, label: "Seleccione ..." }]);
  const [fechaRango, setFechaRango] = useState(null);
  const [fechaIngreso, setFechaIngreso] = useState(null);
  const [diasTrabajados, setDiasTrabajados] = useState(30);
  const [fechaRetiro, setFechaRetiro] = useState(null);
  let api = helpHttp();
  let history = useHistory();

  useEffect(() => {
    const datos = async () => {
      var res = await api.get(`Nomina/Individual?nominaId=${nominaId}`);

      if (res.success) {
        var map = mapDto(res.value);
        setForm(map);
      }
    };

    datos();
  }, []);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const mapDto = (dto) => {
    setFechaRango([
      new Date(dto.fechaLiquidacionInicio),
      new Date(dto.fechaLiquidacionFin),
    ]);
    setFechaIngreso(new Date(dto.empleado.contrato.fechaIngreso));
    setFechaRetiro(new Date(dto.empleado.contrato.fechaRetiro));
    setFechaPago(new Date(dto.fechaPago));
    setDiasTrabajados(dto.diasTrabajados)

    if (dto.empleado.contrato.fechaRetiro) {
      ocultarMostrarFecha()
    }
    return {

      //empleado
      emp_identificacion: dto.empleado.persona.identificacion,
      emp_primerNombre: dto.empleado.persona.primerNombre,
      emp_otrosNombres: dto.empleado.persona.otrosNombres,
      emp_primerApellido: dto.empleado.persona.primerApellido,
      emp_segundoApellido: dto.empleado.persona.segundoApellido,
      emp_correo: dto.empleado.persona.correo,
      emp_municipioId: dto.empleado.persona.municipioId,
      emp_tipoDocumento: dto.empleado.persona.tipoDocumento,
      emp_telefono: "",

      altoRiesgoPension: dto.empleado.contrato.altoRiesgoPension ? "Si" : "No",
      salarioIntegral: dto.empleado.contrato.salarioIntegral ? "Si" : "No",
      lugarTrabajoDireccion: dto.empleado.contrato.lugarTrabajoDireccion,
      lugarTrabajoMunicipioId: dto.empleado.contrato.lugarTrabajoMunicipioId,
      subTipoTrabajadorId: dto.empleado.contrato.subTipoTrabajadorId,
      tipoTrabajadorId: dto.empleado.contrato.tipoTrabajadorId,
      tipoContrato: dto.empleado.contrato.tipoContrato,
      prefijoId: dto.empleado.contrato.prefijoId,
      sueldo: dto.empleado.contrato.sueldo,

      nominaId: dto.empleado.pago.nominaId,
      tipoCuentaId: dto.empleado.pago.tipoCuentaId,
      medioPagoId: dto.empleado.pago.medioPagoId,
      bancoId: dto.empleado.pago.bancoId,
      numeroCuenta: dto.empleado.pago.numeroCuenta,
      fechaPago: dto.empleado.pago.fechaPago,
      formaPagoId: dto.empleado.pago.formaPagoId,

      devengados: dto.empleado.devengados,
      deducciones: dto.empleado.deducciones,

      //empresa

      nit: dto.empresa.nit,
      direccion: dto.empresa.direccion,
      dv: dto.empresa.dv,
      razonSocial: dto.empresa.razonSocial,
      municipioId: dto.empresa.municipioId,
      municipioId: dto.empresa.municipioId,
      municipioId: dto.empresa.municipioId,

      rep_identificacion: dto.empresa.representanteLegal.identificacion,
      rep_primerNombre: dto.empresa.representanteLegal.primerNombre,
      rep_otrosNombres: dto.empresa.representanteLegal.otrosNombres,
      rep_primerApellido: dto.empresa.representanteLegal.primerApellido,
      rep_segundoApellido: dto.empresa.representanteLegal.segundoApellido,
      rep_correo: dto.empresa.representanteLegal.correo,
      rep_tipoPersona: dto.empresa.representanteLegal.tipoPersona,
      rep_municipioId: dto.empresa.representanteLegal.municipioId,
      rep_tipoDocumento: dto.empresa.representanteLegal.tipoDocumento,
      rep_telefono: "",
    };
  };

  const medioPago = () => {
    return (
      form.medioPagoId !== 0 &&
      form.medioPagoId !== 2 &&
      form.medioPagoId !== 9 &&
      form.medioPagoId !== 10 &&
      form.medioPagoId !== 13 &&
      form.medioPagoId !== 16 &&
      form.medioPagoId !== 18 &&
      form.medioPagoId !== 19 &&
      form.medioPagoId !== 26 &&
      form.medioPagoId !== 27 &&
      form.medioPagoId !== 29 &&
      form.medioPagoId !== 30 &&
      form.medioPagoId !== 71
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fechaRango != null && fechaRango.length === 2) {
      const fechaInicio = fechaRango[0].calendar ? new Date(fechaRango[0].format("MM/DD/YYYY")) : fechaRango[0];
      const fechaFin = fechaRango[0].calendar ? new Date(fechaRango[1].format("MM/DD/YYYY")) : fechaRango[1];
      const fIngreso = fechaIngreso.calendar ? new Date(fechaIngreso.format("MM/DD/YYYY")) : fechaIngreso;
      const fPago = fechaPago.calendar ? new Date(fechaPago.format("MM/DD/YYYY")) : fechaPago;
 

      var data = {
        id: nominaId,
        fechaLiquidacionInicio: fechaInicio,
        fechaLiquidacionFin: fechaFin,
        fechaPago: fPago,
        notas: form.notas,
        diasTrabajados: diasTrabajados,

        empleado: {
          persona: {
            identificacion: form.emp_identificacion,
            primerNombre: form.emp_primerNombre,
            otrosNombres: form.emp_otrosNombres,
            primerApellido: form.emp_primerApellido,
            segundoApellido: form.emp_segundoApellido,
            municipioId: parseInt(form.emp_municipioId),
            tipoDocumento: parseInt(form.emp_tipoDocumento),
          },
          contrato: {
            altoRiesgoPension: form.altoRiesgoPension === "Si" ? true : false,
            fechaIngreso: fIngreso,
            fechaRetiro: !liquidacion ? null : fechaRetiro.calendar ? new Date(fechaRetiro.format("MM/DD/YYYY")) : fechaRetiro,
            salarioIntegral: form.salarioIntegral === "Si" ? true : false,
            lugarTrabajoDireccion: form.lugarTrabajoDireccion,
            lugarTrabajoMunicipioId: parseInt(form.lugarTrabajoMunicipioId),
            subTipoTrabajadorId: parseInt(form.subTipoTrabajadorId),
            tipoTrabajadorId: parseInt(form.tipoTrabajadorId),
            tipoContrato: parseInt(form.tipoContrato),
            prefijoId: parseInt(form.prefijoId),
            sueldo: form.sueldo,
          },
          pago: {
            nominaId: nominaId,
            tipoCuentaId: parseInt(form.tipoCuentaId),
            medioPagoId: parseInt(form.medioPagoId),
            bancoId: parseInt(form.bancoId),
            numeroCuenta: form.numeroCuenta,
            fechaPago: form.fechaPago,
            formaPagoId: 1,
          },

          devengados: devengados.map(function (x) {
            return {
              pago: x.pago,
              cantidad: x.cantidad,
              fechaInicio: x.fechaInicio || null,
              fechaFin: x.fechaFin || null,
              tipoDevengadoId: x.tipo,
              id: x.id,
            };
          }),
          deducciones: deducciones.map(function (x) {
            return {
              pago: x.pago,
              cantidad: x.cantidad,
              fechaInicio: x.fechaInicio || null,
              fechaFin: x.fechaFin || null,
              tipoDeduccionId: x.tipo,
              id: x.id,
            };
          }),
        },
        empresa: {
          nit: form.nit,
          direccion: form.direccion,
          dv: form.dv,
          razonSocial: form.razonSocial,
          municipioId: parseInt(form.municipioId),
          representanteLegal: {
            primerNombre: form.rep_primerNombre,
            otrosNombres: form.rep_otrosNombres,
            primerApellido: form.rep_primerApellido,
            segundoApellido: form.rep_segundoApellido,
          },
        },
      };

      let options = {
        body: data,
        headers: { "content-type": "application/json" },
      };

      await api.post("Nomina/Reemplazar", options)
      .then(res => {
        if(res.success){
          Alertas.toastSuccess('Nomia de ajuste creda correctamente')
          history.push("/Home/Nominas/Individual/Lista")
        }
        else {
          Alertas.toastError("Error al generar la nomina")
        }
      })
      .catch(err => {
        console.log(err);
        Alertas.toastError("Error al generar la nomina")
      })
    
    }

  };

  function ocultarMostrarFecha() {
    setLiquidacion(!liquidacion);
  }

  return (
    <>
      <div class="row justify-content-start  mb-5">
        <div class="col-auto">
          <Button
            size="md"
            className="btn btn-light"
            variant="success"
            onClick={() => {
              Swal.fire({
                title: '¿Desea cancelar la nomina de ajuste?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si, Cancelar',
                cancelButtonText: `NO, Seguir Editando`,
                confirmButtonColor: '#198754',
                cancelButtonColor: '#dc3545',
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  history.push("/Home/Nominas/Individual/Lista");
                }
              })

            }}
          >
            <i class="fas fa-arrow-left"></i> <span className="ml-2">Regresar</span>
          </Button>
        </div>
      </div>



      <Form onSubmit={handleSubmit}>
        {/*--------------------------------------- FECHA DE LA NOMINA ---------------------------------------- */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h4 className="separador encabezado-form">Fecha de la NÓMINA</h4>
          <Form.Row className="mb-3">
            <Col lg="3">
              <Row style={{ marginLeft: "0px" }}>
                <Form.Label>Rango de NÓMINA</Form.Label>
              </Row>
              <div className="customDatePickerWidth">
                <DatePicker
                  format="DD/MM/YYYY"
                  range
                  months={months}
                  weekDays={weekDays}
                  render={(value, openCalendar) => {
                    return (
                      <div
                        className="input-group "
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          value={value}
                          onClick={openCalendar}
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={openCalendar}
                          >
                            &#x1f4c5;
                          </span>
                        </div>
                      </div>
                    );
                  }}
                  plugins={[<DatePanel />]}
                  //onChange={ fechaChange(e) }
                  value={fechaRango}
                  onChange={setFechaRango} />
              </div>
            </Col>
            <Col lg="3">
              <Row style={{ marginLeft: "0px" }}>
                <Form.Label>Fecha Pago</Form.Label>
              </Row>

              <div className="customDatePickerWidth">
                <DatePicker
                  format="DD/MM/YYYY"
                  months={months}
                  weekDays={weekDays}
                  render={(value, openCalendar) => {
                    return (
                      <div
                        className="input-group "
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                          value={value}
                          onClick={openCalendar}
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={openCalendar}
                          >
                            &#x1f4c5;
                          </span>
                        </div>
                      </div>
                    );
                  }}
                  value={fechaPago}
                  onChange={setFechaPago} />
              </div>
            </Col>
            <Col lg="3">
              <Row style={{ marginLeft: "0px" }}>
                <Form.Label>Dias Trabajados</Form.Label>
              </Row>
              <Form.Control
                className="input"
                // type="number"
                value={diasTrabajados}
                onChange={(e) => setDiasTrabajados(e.target.value)}
              />
            </Col>
          </Form.Row>
        </div>
        {/*---------------------------------------INFORMACION PERSONAL---------------------------------------- */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h4 className="separador, encabezado-form">
            INFORMACIÓN DE PERSONAL
          </h4>
          <Form.Row>
            <Col>
              <InputEdit
                placeholder="Primer nombre del empleado"
                name="emp_primerNombre"
                handleChange={handleChange}
                value={form.emp_primerNombre}
                label="Primer Nombre"
              />
            </Col>
            <Col>
              <InputEdit
                placeholder="Otros nombres"
                name="emp_otrosNombres"
                handleChange={handleChange}
                value={form.emp_otrosNombres}
                label="Otros Nombres"
              />
            </Col>
            <Col>
              <InputEdit
                placeholder="Primer apellido del empleado"
                name="emp_primerApellido"
                handleChange={handleChange}
                value={form.emp_primerApellido}
                label="Primer Apellido"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col>
              <InputEdit
                placeholder="Segundo apellido del empleado"
                name="emp_segundoApellido"
                handleChange={handleChange}
                value={form.emp_segundoApellido}
                label="Segundo Apellido"
              />
            </Col>
            <Col>
              <InputSelect
                name="emp_tipoDocumento"
                values={form.emp_tipoDocumento}
                handleChange={handleChange}
                label="Tipo De Documento"
                url="Persona/TipoDocumento"
              />
            </Col>
            <Col>
              <InputEdit
                type="number"
                placeholder="12354668752"
                name="emp_identificacion"
                value={form.emp_identificacion}
                handleChange={handleChange}
                label="Numero De Documento"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col md="4">
              <Datalist
                label="Municipio De Trabajo"
                name="emp_municipioId"
                values={form.emp_municipioId}
                handleChange={handleChange}
                placeholder="Municipio de expedicion del documento"
                url="Ubicacion/Municipios"
              />
            </Col>
          </Form.Row>
        </div>
        {/* -------------------------------------------INFORMACION DE CONTRATO---------------------------------- */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h4 className="separador encabezado-form">INFORMACIÓN DE CONTRATO</h4>
          <Form.Row>
            <Col>
              <Form.Label className="label-form">
                Fecha Ingreso<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <div className="customDatePickerWidth">
                <DatePicker
                  format="DD/MM/YYYY"
                  months={months}
                  weekDays={weekDays}
                  render={(value, openCalendar) => {
                    return (
                      <div
                        className="input-group"
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="dd/mm/aaaa"
                          value={value}
                          onClick={openCalendar}
                          readOnly
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={openCalendar}
                          >
                            &#x1f4c5;
                          </span>
                        </div>
                      </div>
                    );
                  }}
                  value={fechaIngreso}
                  onChange={setFechaIngreso}
                />
              </div>
            </Col>
            <Col>
              <Form.Label
                style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
              >
                Alto Riesgo<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="input"
                as="select"
                defaultValue="Seleccionar..."
                name="altoRiesgoPension"
                value={form.altoRiesgoPension}
                onChange={handleChange}
              >
                <option>Seleccionar....</option>
                <option>Si</option>
                <option>No</option>
              </Form.Control>
            </Col>
            <Col>
              <InputEdit
                placeholder="Direccion de trabajo"
                name="lugarTrabajoDireccion"
                value={form.lugarTrabajoDireccion}
                handleChange={handleChange}
                label="Lugar de trabajo"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col>
              <InputSelect
                name="tipoTrabajadorId"
                values={form.tipoTrabajadorId}
                handleChange={handleChange}
                label="Tipo de trabajador"
                placeholder="Seleccionar..."
                url="Empleado/Tipo"
              />
            </Col>
            <Col>
              <InputSelect
                name="subTipoTrabajadorId"
                values={form.subTipoTrabajadorId}
                handleChange={handleChange}
                label="Sub tipo de trabajador"
                placeholder="Seleccionar..."
                url="Empleado/SubTipo"
              />
            </Col>
            <Col>
              <InputSelect
                name="tipoContrato"
                values={form.tipoContrato}
                handleChange={handleChange}
                label="Tipo de contrato"
                placeholder="Seleccionar..."
                url="Empleado/TipoContrato"
              />
            </Col>
          </Form.Row>
          <Form.Row className="separador">
            <Col md="4">
              <Form.Label
                style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }}
              >
                Salario integral<span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                className="input"
                as="select"
                defaultValue="Seleccionar..."
                name="salarioIntegral"
                value={form.salarioIntegral}
                onChange={handleChange}
              >
                <option>Seleccionar</option>
                <option>Si</option>
                <option>No</option>
              </Form.Control>
            </Col>
            <Col md="4">
              <InputEdit
                placeholder="Sueldo Empleado"
                name="sueldo"
                value={form.sueldo}
                onChange={handleChange}
                label="Sueldo Empleado"
              />
            </Col>
          </Form.Row>
          <Form.Row>
            <Col lg={2} xl={2}>
              <div className="form-check mt-5">
                <input className="form-check-input mt-3" type="checkbox" id="defaultCheck1" onClick={ocultarMostrarFecha} checked={liquidacion} />
                <label className="label-input" for="defaultCheck1">
                  Liquidacion
                </label>
              </div>
            </Col>
            {liquidacion ?
              <Col lg={4} xl={4} id="fecha-retiro">
                <Form.Label className="label-form">
                  Fecha Retiro<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="customDatePickerWidth">
                  <DatePicker
                    format="DD/MM/YYYY"
                    months={months}
                    weekDays={weekDays}
                    render={(value, openCalendar) => {
                      return (
                        <div
                          className="input-group"
                          style={{ cursor: "pointer" }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="dd/mm/aaaa"
                            value={value}
                            onClick={openCalendar}
                            readOnly
                          />
                          <div className="input-group-append">
                            <span
                              className="input-group-text"
                              onClick={openCalendar}
                            >
                              &#x1f4c5;
                            </span>
                          </div>
                        </div>
                      );
                    }}
                    value={fechaRetiro}
                    onChange={setFechaRetiro}
                  />
                </div>
              </Col>
              : null}
          </Form.Row>
        </div>
        {/* -----------------------------------------PAGOO------------------------------------------ */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h4 className="separador encabezado-form">MÉTODO DE PAGO</h4>
          <Form.Row>
            <Col md="6">
              <InputEditSelect
                name="medioPagoId"
                value={form.medioPagoId}
                handleChange={handleChange}
                label="Metodo de pago"
                url="Pago/Medios_Pago"
              />
            </Col>
            {medioPago() && (
              <Col>
                <InputEditSelect
                  name="bancoId"
                  label="Bancos"
                  value={form.bancoId}
                  handleChange={handleChange}
                  url="Pago/Bancos"
                />
              </Col>
            )}
          </Form.Row>
          {medioPago() && (
            <Form.Row>
              <Col>
                <InputSelect
                  name="tipoCuentaId"
                  label="Tipo de cuenta"
                  values={form.tipoCuentaId}
                  handleChange={handleChange}
                  url="Pago/Tipos_Cuenta"
                />
              </Col>
              <Col>
                <InputEdit
                  placeholder="Digite el numero de cuenta"
                  name="numeroCuenta"
                  value={form.numeroCuenta}
                  label="Número de cuenta"
                  handleChange={handleChange}
                />
              </Col>
            </Form.Row>
          )}
        </div>
        {/* ------------------------------------------DEVENGADOS--------------------------------------- */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h5 className="separador encabezado-form">DEVENGADOS</h5>

          <DevengadosDeducciones
            valoresIniciales={form.devengados}
            conceptoValues={devengados}
            setConceptoValues={setdevengados}
            endPoint="Devengado/Tipos"
          />
        </div>
        {/* ------------------------------------DEDUCCIONES---------------------------------------- */}
        <div className="mb-5 pb-5 border-bottom border-secondary">
          <h5 className="separador encabezado-form">DEDUCCIONES</h5>

          <DevengadosDeducciones
            valoresIniciales={form.deducciones}
            conceptoValues={deducciones}
            setConceptoValues={setdeducciones}
            endPoint="Deduccion/Tipos"
          />
        </div>

        {/* -------------------------------------------------- GUARDAR  ---------------------------------------- */}

        <Button className="btn btn-success mr-3 " size="md" type="submit">
          Guardar
        </Button>

        <Button
          size="md"
          className="btn btn-light"
          variant="success"
          onClick={() => {
            Swal.fire({
              title: '¿Desea cancelar la nomina de ajuste?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Si, Cancelar',
              cancelButtonText: `NO, Seguir Editando`,
              confirmButtonColor: '#198754',
              cancelButtonColor: '#dc3545',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                history.push("/Home/Nominas/Individual/Lista");
              }
            })

          }}
        >
          Regresar
        </Button>

      </Form>
    </>
  );
};
