import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from '../../Recursos/Alertas'
import Swal from 'sweetalert2';
import fileUtils from '../../Utilidades/fileUtils' 
import fechaUtils from "../../Utilidades/fechaUtils";
import { Select2 } from "../../Componentes/Select/Select2";
import { RangoFechas } from "../../Componentes/Fechas/RangoFechas";
import {DateObject} from "react-multi-date-picker";
import { Loader } from "../../Recursos/Loader";
import { Form } from "react-bootstrap";
var qs = require('qs');
 
let api = helpHttp();

/*------------------------ Overlay----------------------------------- */

const eliminar = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Eliminar
  </Tooltip>
);

const dian = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Ver en el modulo de la DIAN
  </Tooltip>
);

const correo = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Enviar al Correo
  </Tooltip>
);

const pdf = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Click para descargar Pdf
  </Tooltip>
);

const remplazar = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Nomina de Ajuste
  </Tooltip>
);

const refresh = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Refrescar Tabla
  </Tooltip>
);

var estados = [
  {
    label : 'PROCESADO',
    value : 5,
  },
  {
    label : 'INICIANDO PROCESO',
    value : 1,
  },
  {
    label : 'ENVIANDO A LA DIAN',
    value : 4,
  },
  {
    label : 'Seleccione',
    value : 0,
  }
]

async function descargarPlantilla(nominaId) {
  var res = await api.post(`Nomina/Pdf?nominaId=${nominaId}`)

  if (res.success) {
    fileUtils.downloadBase64(res.value);
  } else {
    Alertas.toastWarning("No se pudo descargar la plantilla")
  }
}



export const TablaNominas = ({ setNominaId }) => {
  var date = new DateObject()
  const [data, setData] = useState(null);
  const [cargando, setCargando] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [seleccionados, setSeleccionados] = useState(0);
  const [estado, setEstado] = useState({ label : 'Seleccione', value: 0 });
  const [filterText, setFilterText] = React.useState('');
  const [fecha, setFecha] = useState(date); 

  async function filtarTabla(verMensaje = false) {
    var f  ={
      mes : fecha.format("MM"),
      año : fecha.format("YYYY"),
      estado : estado.value,
    }
    setData(null) 

    var res = await api.get(`Nomina/Individual/Lista?mes=${f.mes}&año=${f.año}&estado=${f.estado}`);
    if (res.success) {
      setData(res.value);
      if(verMensaje){
        Alertas.toastSuccess("Filtro Aplicado", 1000, true)
      }
    }
  }

  useEffect(() => { 
    filtarTabla(true); 
  }, [estado, fecha]); 
   
  const viewer = (id) => {
    setNominaId(id);
  };

  const eliminarNomina = async (id) => {
    setNominaId(id);

    var result = await Swal.fire({
      title: '¿Esta seguro que desea eliminar la nómina?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Eliminar',
      cancelButtonText: `NO, Cancelar`,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#dc3545',
    })

    if (result.isConfirmed) {
      var res = await api.del(`Nomina/ELiminar?nominaId=${id}`)
      if (res.success) {
        Alertas.toastSuccess("La eliminacion de la nomina ha entrado a la cola de procesos")
      }
    }

    filtarTabla();
  };

  const justifyContentCenter = { 
    justifyContent: "center",
  };

  async function enviarCorreo(nominaId){
    setCargando(true);

    await api.post(`Nomina/Correo?nominaId=${nominaId}`)
      .then(res => {
        if (res.success) { 
          Alertas.toastSuccess("Correo enviado correctamente")
        }  
      })
      .catch(err => {

      })

    setCargando(false);
  }

  const columns = [
    {
      name: "#",
      selector: (row) => row.numero,
      minWidth: "90px",
      maxWidth: "90px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: "Identificacion",
      selector: (row) => row.identificacion,
      minWidth: "150px",
      maxWidth: "150px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      sortable: true,
    },
    {
      name: "Creada",
      selector: (row) => fechaUtils.formatoFecha(row.fechaGeneracion),
      maxWidth: "110px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: "Rango Fecha",
      selector: (row) => `${new Date(row.fechaLiquidacionInicio).toLocaleDateString("en-GB")} ~ ${new Date(row.fechaLiquidacionFin).toLocaleDateString("en-GB")}`,
      maxWidth: "200px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: "Estado",
      selector: (row) => row.estado,
      maxWidth: "120px",
      style: justifyContentCenter,
      sortable: true,
    },
    {
      name: "Acciones", 
      cell: (row) => (
        <div className="d-flex justify-content-evenly ">

          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={pdf}
              transition={false} >
              <Link
                style={{ cursor: "pointer", color: "#2f56c7" }}
                size="sm"
                onClick={() => {
                  if (row.estado ==='PROCESADO') {
                    descargarPlantilla(row.id)
                  } else {
                    Alertas.toastWarning("La nomina no ha sido procesada")
                  }
                  //viewer(row.id)
                }}
              >
                <i className="fas fa-file-pdf"></i>
              </Link>
            </OverlayTrigger>
          </div>

          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={correo}
              transition={false} >
              <Link
                style={{ cursor: "pointer", color: "#ffc107" }}
                size="sm"
                onClick={() => {
                  if (row.estado ==='PROCESADO') {
                    enviarCorreo(row.id)
                  } else {
                    Alertas.toastWarning("La nomina no ha sido procesada")
                  } 
                }}
              >
                <i className="fas fa-envelope"></i>
              </Link>
            </OverlayTrigger>
          </div>

          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={dian}
              transition={false}
            >
              <Link
                style={{ cursor: "pointer", color: "BLACK" }}
                size="sm"
                onClick={() => {
                  if (row.estado == "PROCESADO" && row.codigoQR) {
                    window.open(row.codigoQR, '_blank').focus();
                  } else {
                    Alertas.toastWarning("La nomina no ha sido procesada")
                  }
                  //viewer(row.id)
                }}
              >
                <i className="fas fa-qrcode"></i>
              </Link>

            </OverlayTrigger>
          </div>

          <div className="p-2">
            <OverlayTrigger delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={remplazar}
              transition={false} >
              {row.eliminada
              ? (<Link style={{ cursor: "pointer", color: "green" }}
                    size="sm"  >
                    <i className="fas fa-file"></i>
                  </Link>)
              : (<Link style={{ cursor: "pointer", color: "green" }}
                    size="sm"
                    onClick={() => viewer(row.id)}
                    to="/Home/Nominas/Individual/Lista/Ajuste" >
                    <i className="fas fa-file"></i>
                  </Link>)}
            </OverlayTrigger>
          </div>

          <div className="p-2">
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={eliminar}
              transition={false}
            >
              <Link
                style={{ cursor: "pointer", color: "#dc3545" }}
                size="sm"
                onClick={() => 
                  {
                    if (row.eliminada) {
                      Alertas.toastWarning("La nomina ya ha sido eliminada")
                    } else {
                      eliminarNomina(row.id)
                    }
                  }
                }
              >
                <i className="fas fa-trash"></i>
              </Link>

            </OverlayTrigger>
          </div> 
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      minWidth: '150px',
      sortable: true,

    },
  ];
   
  const conditionalRowStyles = [
    {
      when: row => row.eliminada ===true,
      style: {
        backgroundColor: '#ffe5e5', 
      },
    },
  ]
 

  const removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
  } 

  async function descargarPdfs(){
    setCargando(true);
    var filtro = selectedRows.filter(x => x.estado == "PROCESADO");
    if(filtro.length > 0) { 
      let param = {
        ids : filtro.map(x => x.id)
      }
      let stringify = qs.stringify( param, {arrayFormat: 'repeat', encode: false})
      await api.get(`Nomina/Pdf/Lote?${stringify}`)
      .then(res => {
        console.log(res)
        if(res.success){
            fileUtils.downloadBase64(res.value);
        }else{
            Alertas.toastWarning("No se pudo descargar los pdf")
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
    else {
      Alertas.toastError("Seleccione las nominas para descargar su pdf");
    }
    setCargando(false);
  } 

  async function enviarCorreos(){
    setCargando(true);
    var filtro = selectedRows.filter(x => x.estado == "PROCESADO");
    if(filtro.length > 0) {
 
      let param = {
        ids : filtro.map(x => x.id)
      }
      let stringify = qs.stringify( param, {arrayFormat: 'repeat', encode: false})

      await api.post(`Nomina/Correo/Lote?${stringify}`)
      .then(res => {
        console.log(res)
        if(res.success){
            Alertas.toastSuccess("Correos enviados correctamente")
        }else{
            Alertas.toastWarning("No se pudo enviar los correos")
        }
      })
      .catch(err => {
        console.log(err)
      })
    }
    else {
      Alertas.toastError("Seleccione las nominas para enviar los correos");
    }
    setCargando(false); 
  }

  async function eliminarNominas() {
    var filtro = selectedRows.filter(x => !x.eliminada);
    if(filtro.length > 0) {
      
      let param = {
        ids : filtro.map(x => x.id)
      }
      let stringify = qs.stringify( param, {arrayFormat: 'repeat', encode: false})

      var result = await Swal.fire({
        title: '¿Esta seguro que desea eliminar las nóminas?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, Eliminar',
        cancelButtonText: `NO, Cancelar`,
        confirmButtonColor: '#198754',
        cancelButtonColor: '#dc3545',
      })
  
      if (result.isConfirmed) {
        setCargando(true);
        await api.del(`Nomina/ELiminar/Lote?${stringify}`)
          .then(res => {
            if (res.success) {
              Alertas.toastSuccess("La eliminacion de las nominas ha entrado a la cola de procesos");
              filtarTabla();
            }
            else{
              Alertas.toastWarning("No se pudo eliminar las nominas")
            }
          })
          .catch(err => {
            console.log(err)
          })
        
      } 
    }
    else {
      Alertas.toastError("Seleccione las nominas para eliminar");
    }
    setCargando(false); 
  }



	const subHeaderComponentMemo = React.useMemo(() => {
		return (
      <>
        <div className="row flex-1 mt-3 "> 
        
          <div className="col col-md-4 d-flex d-gap-30">
            <Form.Control 
              onChange={e => setFilterText(e.target.value)}
              placeholder="Buscar"
            />
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={refresh}
              transition={false}>
              <button className="btn btn-primary" onClick={filtarTabla} style={{width: "30px", padding: "0", flexShrink: "0"}}>
                <i class="fa fa-refresh"></i> 
              </button>
            </OverlayTrigger>  
          </div>
          <div className="col col-md-2">
            <RangoFechas mes={fecha} setMes={setFecha} />
          </div>
          <div className="col col-md-3">
            <Select2 data={estados} value={estado} selectChange={setEstado} />
          </div>
          

          <div className="col col-md-4 d-flex d-gap-30 flex-1 justify-content-end">
            <span style={{ lineHeight: "33.5px" }}>{seleccionados} seleccionadas </span>
            
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={pdf}
              transition={false}>
              <button className="btn btn-primary" onClick={descargarPdfs} style={{width: "30px", padding: "0"}}>
                <i class="fas fa-file-pdf"></i> 
              </button>
            </OverlayTrigger>  

            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={correo}
              transition={false}>
              <button className="btn btn-warning" onClick={enviarCorreos} style={{width: "30px", color: "white", padding: "0"}}>
                <i class="fas fa-envelope"></i> 
              </button>
            </OverlayTrigger>  
            
            <OverlayTrigger
              delay={{ show: 10, hide: 10 }}
              placement="top"
              overlay={eliminar}
              transition={false}>
                <button className="btn btn-danger" onClick={eliminarNominas} style={{width: "30px", padding: "0"}} >
                  <i class="fas fa-trash"></i> 
                </button>
              </OverlayTrigger>
          </div>
 
        </div>

        <hr className="w-100" />   

      </>
		);
	}, [filterText, seleccionados, estado, fecha]);

  function filtar(){
    return data.filter(x => x.identificacion.includes(filterText)
        || x.numero.includes(filterText)
        || removeAccents(x.nombre).includes(filterText.toLocaleLowerCase())
    )  
  }

  const SelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
    setSeleccionados(selectedRows.length)
  };

  return (
    <> 
      {cargando
      ? <Loader />
      : (<div className="row">
      <div className="col-12">
        {data ? <DataTable columns={columns} 
                          subHeader
                          subHeaderComponent={subHeaderComponentMemo}
                          onSelectedRowsChange={SelectedRowsChange}
                          data={filterText ? filtar() : data} 
                          selectableRows
                          conditionalRowStyles={conditionalRowStyles}
                          pagination />
              : <Loader />}
      </div>
    </div>)}

      
    </>
  );
};
