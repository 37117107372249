import React from "react";
import { Badge } from "react-bootstrap";
import { Empresa } from "./Empresa/Empresa";

export const AggEmpresa = () => {
  return (
    <>
      <div className="grid-container">
        <header className="titulo">
          <h1>
            <Badge className="tituloPrincipal" variant="light">
              AGREGAR EMPRESA
            </Badge>
          </h1>
        </header>
        <section className="render">
          <Empresa />
        </section>
      </div>
    </>
  );
};
