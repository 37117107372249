

 


const formatoFecha = (dateTime) => {
  
  let date = new Date(`${dateTime}`)

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  day = day < 10 ? `0${day}` : day
  month = month < 10 ? `0${month}` : month

  return `${day}/${month}/${year}`

}

const toStr_yyyy_mm_dd = (date) => {
    
  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  day = day < 10 ? `0${day}` : day
  month = month < 10 ? `0${month}` : month

  return `${year}-${month}-${day}`

}

export default { formatoFecha, toStr_yyyy_mm_dd }