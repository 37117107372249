import React, { useState } from "react";
import "./Empleado.css";
import { Form, Button, Col } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { InfoPersonal } from "./Datos/InfoPersonal";
import { InfoContrato } from "./Datos/InfoContrato";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { useHistory } from "react-router";
import { Input } from "../../Componentes/Input/Input";
import { DevengadosDeducciones } from "../../Componentes/DevengadosDeducciones/DevengadosDeducciones";
import Swal from "sweetalert2";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from "../../Recursos/Alertas"; 
import withReactContent from 'sweetalert2-react-content'

//Validar campos
const validacion = Yup.object().shape({
  /* ----------------------------------------------------Informacion Personal--------------------- */
  //nombre: Yup.string().max(50).required("Campo obligatorio"),
  //otronombre: Yup.string().max(150),
  //apellido: Yup.string().max(50).required("Campo obligatorio"),
  //otroapellido: Yup.string().max(50),
  //tipodocumento: Yup.string().required("Campo obligatorio"),
  //ndocumento: Yup.number().positive().integer("Escriba el numero sin puntos").required("Campo obligatorio"),
  correo: Yup.string().email("No es un email valido").required("Campo obligatorio"),
  /* --------------------------------------------------Informacion De Contrato-------------------------- */
 // fechaingreso: Yup.date().required("Campo obligatorio"),
  //altoriesgo: Yup.string().required("Campo obligatorio"),
  //lugartrabajo: Yup.string().max(150).required("Campo obligatorio"),
  //tptrabajador: Yup.string().required("Campo obligatorio"),
  //slintegral: Yup.string().required("Campo obligatorio"),
  /* -----------------------------------------------------Metodo De Pago-------------------------------- */
  //mtpago: Yup.string().required("Campo obligatorio"),
  //banco: Yup.string(),
  //tpcuenta: Yup.string(),
  //ncuenta: Yup.string(),
});

export const Empleado = ({ mensajeCredo }) => {
  const MySwal = withReactContent(Swal)
  let api = helpHttp();
  let history = useHistory();
  const [devengados, setdevengados] = useState([{ value: 0, label: "Seleccione ..." }]);
  const [deducciones, setdeducciones] = useState([{ value: 0, label: "Seleccione ..." }]);
  const [liquidacion, setLiquidacion] = useState(false);


  const crearEmpleado = async (data) => {

    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };

    var res = await api.post("Empleado", options);

    if (res.success) {
      mensajeCredo();
      history.push("/Home/Lista/Empleados");
    } else {
      Alertas.toastInfo("Revise los datos del empleado")
    }


  };

  function regresar(){
    Swal.fire({
      title: '¿Los Cambios no se guardarán, está seguro?', 
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Cancelar',
      cancelButtonText: `NO, Seguir creando`,
      confirmButtonColor: '#198754',
      cancelButtonColor: '#dc3545',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        history.push("/Home/Lista/Empleados");
      } 
    })
  }

  return (
    <>
      <Button className="btn btn-light btn-rigth btn-lg"  
              onClick={regresar} >
        <i className="fas fa-arrow-left"> <span className="ml-3">Regresar</span></i>
      </Button>
 

      <hr />
      {/* --------------------------------------------------INFORMACION PERSONAL-------------------------------------------------------------- */}
      
      <Formik
        initialValues={
          {
            /* -----------------Informacion Personal---------------- */
            nombre: "",
            otronombre: "",
            apellido: "",
            otroapellido: "",
            tipodocumento: "",
            ndocumento: "",
            municipio: "",
            tpersona: "",
            correo: "",
            //telefono: "",
            /* -----------------Informacion Contrato---------------- */
            fechaingreso: "",
            fechaRetiro: "",
            altoriesgo: "",
            lugartrabajo: "",
            tptrabajador: "",
            sbtptrabajador: "",
            tpcontrato: "",
            slintegral: "",
            pempleado: "",
            /* -----------------Metodo Pago--------------------- */
            mtpago: "",
            banco: "",
            tpcuenta: "",
            ncuenta: "",
        }}


        validationSchema={validacion}
        onSubmit={(data) => {

          var empleado = {
            persona: {
              identificacion: `${data.ndocumento}`,
              primerNombre: `${data.nombre}`,
              otrosNombres: `${data.otronombre}`,
              primerApellido: `${data.apellido}`,
              segundoApellido: `${data.otroapellido}`,
              correo: `${data.correo}`,
              tipoPersona: parseInt(data.tpersona),
              municipioId: parseInt(data.municipioT),
              tipoDocumento: parseInt(data.tipodocumento),
              //contacto: [`${data.telefono}`],
            },
            contrato: {
              altoRiesgoPension: data.altoriesgo === "Si" ? true : false,
              fechaIngreso: `${data.fechaingreso}`,
              fechaRetiro: `${data.fechaRetiro}` ==='' || !liquidacion ? null : `${data.fechaRetiro}`,
              salarioIntegral: data.slintegral === "Si" ? true : false,
              lugarTrabajoDireccion: `${data.lugartrabajo}`,
              lugarTrabajoMunicipioId: parseInt(data.municipioT),
              subTipoTrabajadorId: parseInt(data.sbtptrabajador),
              tipoTrabajadorId: parseInt(data.tptrabajador),
              tipoContrato: parseInt(data.tpcontrato),
              // prefijoId: parseInt(data.pempleado),
              sueldo: parseFloat(data.sueldo), // ----- sin crear
            },
            pago: {
              tipoCuentaId: parseInt(data.tpcuenta),
              medioPagoId: parseInt(data.mtpago),
              bancoId: parseInt(data.banco),
              numeroCuenta: `${data.ncuenta}`,
              //fechaPago: `2021-09-26T00:00:00.501Z`,  -en la configuracion de la empresa no se coloca nada
              //formaPagoId: 0 -- forma pago revisar
            },
            devengados: devengados.map(function (x) {
              return {
                pago: x.pago,
                cantidad: x.cantidad,
                fechaInicio: x.fechaInicio || null,
                fechaFin: x.fechaFin || null,
                tipoDevengadoId: x.tipo
              }
            }),
            deducciones: deducciones.map(function (x) {
              return {
                pago: x.pago,
                cantidad: x.cantidad,
                fechaInicio: x.fechaInicio || null,
                fechaFin: x.fechaFin || null,
                tipoDeduccionId: x.tipo
              }
            })
          };
 
          crearEmpleado(empleado);

        }}
      >
        {({ handleSubmit, handleChange, touched, errors, values }) => (
          <Form onSubmit={handleSubmit}>


            {/*--------------------------------------------------------- INFORMACION PERSONAL ------------------------------------------------ */}
            <div>
              <InfoPersonal handleChange={handleChange}
                touched={touched}
                errors={errors}
                values={values}
              />
            </div>
            {/* -------------------------------------------------------- INFORMACION DE CONTRATO------------------------------------------------------- */}
            <div>
              <hr style={{ marginTop: "80px" }}></hr>
              <h4
                style={{
                  fontSize: "20Px",
                  textAlign: "start",
                  marginBottom: "50px",
                }}
                className="separador"
              >
                INFORMACIÓN DE CONTRATO
              </h4>
              <InfoContrato
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                values={values}
                liquidacion={liquidacion}
                setLiquidacion={setLiquidacion}
              />
              <hr style={{ marginTop: "80px" }} />
            </div> 
            {/* -------------------------------------------------------------------------METODO DE PAGO------------------------------------------------------------------- */}
            <div>

              <h4
                style={{
                  fontSize: "20Px",
                  textAlign: "start",
                  marginBottom: "50px",
                }}
                className="separador"
              >
                MÉTODO DE PAGO
              </h4>
              <Form.Row>
                <Col md="6">
                  <InputSelect
                    label="Metodo de pago"
                    name="mtpago"
                    value={values.mtpago}
                    handleChange={handleChange}
                    url="Pago/Medios_Pago"
                  />
                  {touched.mtpago && errors.mtpago ? (
                    <div className="error">{errors.mtpago}</div>
                  ) : null}
                </Col>
                {values.mtpago !== "0" &&
                  values.mtpago !== "2" &&
                  values.mtpago !== "9" &&
                  values.mtpago !== "10" &&
                  values.mtpago !== "13" &&
                  values.mtpago !== "16" &&
                  values.mtpago !== "18" &&
                  values.mtpago !== "19" &&
                  values.mtpago !== "26" &&
                  values.mtpago !== "27" &&
                  values.mtpago !== "29" &&
                  values.mtpago !== "30" &&
                  values.mtpago !== "71" && (
                    <Col>
                      <InputSelect
                        label="Bancos"
                        name="banco"
                        value={values.banco}
                        handleChange={handleChange}
                        url="Pago/Bancos"
                      />
                      {touched.banco && errors.banco ? (
                        <div className="error">{errors.banco}</div>
                      ) : null}
                    </Col>
                  )}
              </Form.Row>
              {values.mtpago !== "0" &&
                values.mtpago !== "2" &&
                values.mtpago !== "9" &&
                values.mtpago !== "10" &&
                values.mtpago !== "13" &&
                values.mtpago !== "16" &&
                values.mtpago !== "18" &&
                values.mtpago !== "19" &&
                values.mtpago !== "26" &&
                values.mtpago !== "27" &&
                values.mtpago !== "29" &&
                values.mtpago !== "30" &&
                values.mtpago !== "71" && (
                  <Form.Row>
                    <Col>
                      <InputSelect
                        label="Tipo de cuenta"
                        name="tpcuenta"
                        value={values.tpcuenta}
                        handleChange={handleChange}
                        url="Pago/Tipos_Cuenta"
                      />
                      {touched.tpcuenta && errors.tpcuenta ? (
                        <div className="error">{errors.tpcuenta}</div>
                      ) : null}
                    </Col>
                    <Col>
                      <Input
                        type="text"
                        placeholder="Digita el numero de cuenta "
                        name="ncuenta"
                        value={values.ncuenta}
                        handleChange={handleChange}
                        label="Número de cuenta"
                      />
                      {touched.ncuenta && errors.ncuenta ? (
                        <div className="error">{errors.ncuenta}</div>
                      ) : null}
                    </Col>
                  </Form.Row>
                )}
            </div>
            {/*--------------------------------------------------------- DEVENGADOS --------------------------------------------------------*/}
            <div>
              <hr style={{ marginTop: "80px" }} />
              <h5
                style={{
                  fontSize: "20px",
                  textAlign: "start",
                  marginBottom: "50px",
                }}
                className="separador"
              >
                DEVENGADOS
              </h5>

              <DevengadosDeducciones conceptoValues={devengados} setConceptoValues={setdevengados} endPoint="Devengado/Tipos" />

            </div>
            {/*--------------------------------------------------------- DEDUCCIONES------------------------------------------------ */}
            <div>
              <hr style={{ marginTop: "80px" }} />
              <h5
                style={{
                  fontSize: "20px",
                  textAlign: "start",
                  marginBottom: "50px",
                }}
                className="separador"
              >
                DEDUCCIONES
              </h5>

              <DevengadosDeducciones conceptoValues={deducciones} setConceptoValues={setdeducciones} endPoint="Deduccion/Tipos" />

            </div>
            {/* -------------------------------------------------------- GUARDAR---------------------------------------------------------------- */}
            <div className="mb-5">
              <hr style={{ marginTop: "80px" }} />
              {/* <Alert variant="warning">
                Los campos marcados con <span style={{ color: "red" }}>*</span> son obligatorios
              </Alert> */}

              <Button size="lg" className="btn btn-success mr-3" type="submit">
                Guardar
              </Button>

              <Button size="lg"
                      className="btn btn-light"
                      variant="danger"
                      onClick={regresar}
                      style={{ marginRight: "10px" }} >
                Cancelar
              </Button>
              
            </div>

          </Form>
        )}
      </Formik>
    </>
  );



};
