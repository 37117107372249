import React, {useEffect, useState} from "react";
import { Badge } from "react-bootstrap";
import { EditEmpresa } from "./Empresa/EditEmpresa";
import { helpHttp } from "../Recursos/helpHttp";


export const VerEmpresa= () => {
  const [razonSocial, setRazonSocial] = useState("");

  let api=helpHttp()

  useEffect(() => {
    const datos = async () => {
      var res = await api.get("Empresa");
      if (res.success) {
        setRazonSocial(res.value.razonSocial)
      }
    };
    datos();
  }, []);

  return (
    <>
      <div className="grid-container">
        <header className="titulo">
          <h1>
            <Badge className="tituloPrincipal" variant="light">
              {razonSocial.toUpperCase() }
            </Badge>
          </h1>
        </header>
        <section className="render">
          <EditEmpresa/>
        </section>
      </div>
    </>
  );
};
