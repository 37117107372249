import React, { useState } from "react";
import { Form, Col } from "react-bootstrap";
import { Datalist } from "../../../Componentes/Input/Datalist";
import { Input } from "../../../Componentes/Input/Input";
import { InputSelect } from "../../../Componentes/Input/InputSelect";

export const InfoContrato = ({ handleChange, values, errors, touched, liquidacion, setLiquidacion }) => {


 

  function ocultarMostrarFecha(){
    setLiquidacion(!liquidacion);
  }



  return (
    <>
      {/* Cuarta Fila */}
      <Form.Row>
        <Col lg={4} xl={4}>
          <Form.Label className="label-input">
            Fecha de ingreso<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            type="date"
         
            name="fechaingreso"
            value={values.fechaingreso}
            onChange={handleChange}
          />
          {errors.fechaingreso && touched.fechaingreso ? (
            <div className="error">{errors.fechaingreso}</div>
          ) : null}
        </Col>
        
        <Col lg={4} xl={2}>
          <Form.Label className="label-input">
            Alto Riesgo<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            as="select"
            defaultValue="Seleccionar..."
            name="altoriesgo"
            value={values.altoriesgo}
            onChange={handleChange}
          >
            <option>Seleccionar</option>
            <option>Si</option>
            <option>No</option>
          </Form.Control>
          {errors.altoriesgo && touched.altoriesgo ? (
            <div className="error">{errors.altoriesgo}</div>
          ) : null}
        </Col>
        <Col lg={6} xl={6}>
          <Form.Label className="label-input">
            Lugar de trabajo<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            type="text"
           
            name="lugartrabajo"
            value={values.lugartrabajo}
            onChange={handleChange}
          />
          {errors.lugartrabajo && touched.lugartrabajo ? (
            <div className="error">{errors.lugartrabajo}</div>
          ) : null}
        </Col> 
        <Col lg={6} xl={4}>
          <Datalist
            name="municipioT"
            value={values.municipioT}
            handleChange={handleChange}
            id="municipioP"
            label="Municipio"
            placeholder="Municipio de trabajo"
            url="Ubicacion/Municipios"
          />
          {errors.municipioT && touched.municipioT ? (
            <div className="error">{errors.municipioT}</div>
          ) : null}
        </Col>
        <Col lg={6} xl={4}>
          <InputSelect
            name="tptrabajador"
            value={values.tptrabajador}
            handleChange={handleChange}
            label="Tipo de trabajador"
            url="Empleado/Tipo"
          />
          {errors.tptrabajador && touched.tptrabajador ? (
            <div className="error">{errors.tptrabajador}</div>
          ) : null}
        </Col>
        <Col lg={6} xl={4}>
          <InputSelect
            name="sbtptrabajador"
            value={values.sbtrabajador}
            handleChange={handleChange}
            label="Sub tipo de trabajador"
            url="Empleado/SubTipo"
          />
        </Col> 
        <Col lg={6} xl={4}>
        <InputSelect
        name="tpcontrato"
        value={values.tpcontrato}
        handleChange={handleChange}
        label="Tipo de contrato"
        url="Empleado/TipoContrato"
        />
          {errors.tpcontrato && touched.tpcontrato ? (
            <div className="error">{errors.tpcontrato}</div>
          ) : null}
        </Col> 
        <Col lg={4} xl={4}>
        <Input
          placeholder="Salario del empleado"
          label="Sueldo"
          type="number"
          name="sueldo"
          value={values.sueldo}
          handleChange={handleChange}
        />
        </Col>
        <Col lg={2} xl={2}>
          <Form.Label className="label-input">
            Salario integral<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            as="select"
            defaultValue="Seleccionar..."
            name="slintegral"
            value={values.slintegral}
            onChange={handleChange}
          >
            <option>Seleccionar</option>
            <option>Si</option>
            <option>No</option>
          </Form.Control>
          {errors.slintegral && touched.slintegral ? (
            <div className="error">{errors.slintegral}</div>
          ) : null}
        </Col> 
        
      </Form.Row>
      <Form.Row>
        <Col lg={2} xl={2}>
        <div className="form-check mt-5">
          <input className="form-check-input mt-3" type="checkbox" value="" id="defaultCheck1" onClick={ocultarMostrarFecha} checked={liquidacion}/>
          <label className="label-input" for="defaultCheck1">
            Liquidacion
          </label>
        </div> 
        </Col> 
        {liquidacion ? 
            <Col lg={4} xl={4} id="fecha-retiro">
              <Form.Label className="label-input">
                Fecha de retiro
              </Form.Label>
              <Form.Control
                className="input"
                type="date" 
                name="fechaRetiro"
                value={values.fechaRetiro}
                onChange={handleChange}
              /> 
            </Col>
          : null}
      </Form.Row>
    </>
  );
};
