import React from "react";
import { Badge } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"; 
import { TablaNominasEliminacion } from "./TablaNominasEliminacion"; 

export const ListaNominaEliminacion = () => {
 
  return (
    <div className="grid-container">
      <Switch> 
        <Route exact path="/Home/Nominas/Eliminada/Lista">
          <header className="titulo">
            <h1>
              <Badge className="tituloPrincipal" variant="light">
                NÓMINAS DE ELIMINACION ELECTRÓNICAS
              </Badge>
            </h1>
            <hr></hr>
          </header>
          <section className="render mb-5">
            <TablaNominasEliminacion  />
          </section>
        </Route> 
      </Switch>
    </div>
  );
};
