import React, { useState } from "react";
import { Badge } from "react-bootstrap";
import {  Switch, Route } from "react-router-dom";
import { TablaNominas } from "./TablaNominas";
import { Nominajuste } from "../NominaAjuste/Nominajuste"; 

export const ListaNomina = () => {

  const [nominaId, setNominaId] = useState(0);
 
  return (
    <div className="grid-container">
   
        <Switch>
          <Route exact path="/Home/Nominas/Individual/Lista">
            <header className="titulo">
              <h1>
                <Badge className="tituloPrincipal" variant="light">
                  NÓMINAS ELECTRÓNICAS
                </Badge>
              </h1>
              <hr></hr>
            </header> 
            <section className="render mb-5">
              <TablaNominas setNominaId={setNominaId} />
            </section>
          </Route>
          <Route exact path="/Home/Nominas/Individual/Lista/Ajuste">
            <header className="titulo">
              <h1>
                <Badge className="tituloPrincipal" variant="light">
                  AJUSTE DE NÓMINA 
                </Badge>
              </h1>
              <hr></hr>
            </header>
            <section className="render mb-5">
              <Nominajuste nominaId={nominaId} />
            </section>
          </Route>
        </Switch>

    </div>
  );
};
