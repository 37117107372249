import Alertas from "./Alertas";
const baseUrl = window.location.host.includes('nomina')
  ? "https://apinomina.apl.com.co/api/" 
  : "https://localhost:44395/api/";

class result {
  constructor(message, errorCode, refresToken = false) {
    this.errors = [{ message: message, errorCode: errorCode }];
    this.success = false || refresToken;
    this.refresToken = refresToken
  }
}
  
export const helpHttp = () => {
 
  const makePetition = async (endpoint, options) => {
    var res = await customFetch(endpoint, options);
    // if (res.refresToken) {
    //   await refresToken();
    //   var res2 = await customFetch(endpoint, options);
    //   return res2
    // }
    return res;
  };

  const customFetch = async (endpoint, options, mostrar=false) => {

    const defaultHeader = { accept: "application/json" };
    options.credentials = "include";
    options.headers = options.headers ? { ...defaultHeader, ...options.headers } : defaultHeader;
    options.body = JSON.stringify(options.body) || false;

    if (!options.body) delete options.body;

    try {
      var res = await fetch(baseUrl + endpoint, options)
        .then((res) => {
          if (res.status === 401) { return new result("Token invalido", 401, true) }
          return res.json();
        })
        .then((json) => json)

      if (!res.success && mostrar) { Alertas.MostarErrores(res.errors);}

      return res;

    } catch (e) { return new result("Error en el servidor", 0); }
  };
 
  const get = async (endPoint, options = {}) => {
    options.method = "GET";
    return await makePetition(endPoint, options);
  };

  const post = (endPoint, options = {}, mostrar=false) => {
    options.method = "POST";
    return makePetition(endPoint, options, mostrar);
  };

  const put = async (endPoint, options = {}) => {
    options.method = "PUT";
    return await makePetition(endPoint, options);
  };

  const del = (endPoint, options = {}) => {
    options.method = "DELETE";
    return makePetition(endPoint, options);
  };

  
  return {
    get,
    post,
    put,
    del,
  };
};
