import React, { useState, useEffect } from "react";
import { AggConfiguracion } from "./AggConfiguracion";
import { helpHttp } from "../../Recursos/helpHttp";
import Alertas from "../../Recursos/Alertas";

export const Configuracion = () => {
  const [Db, setDb] = useState(null);
  const [dataToEdit, setDataToEdit] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let api = helpHttp();

  useEffect(() => {
    window.addEventListener('storage', () => {
      const theme = localStorage.getItem('user')
    })
  }, []);

  useEffect(() => {
 
    const cargarConfiguracion = async () => {
      setLoading(true);
      var res = await api.get('Configuracion')
        if (res.success) {
        setDb(res.value);
        setError(null);
      } 
      setLoading(false);
    }
    cargarConfiguracion()

  }, []);




  /* -------------------------------------------------------------------Funcion Crear--------------------------------------------------- */
  const createData = async (data) => {

    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };

    var res = await api.post('Configuracion', options);

    if(res.success){
      Alertas.toastSuccess("Configuracion Guardada")
      var user = JSON.parse(localStorage.getItem('user'))
      user.tipoAmbiente = res.value.ambienteId
      localStorage.setItem('user', JSON.stringify(user))

    }

  };

  return (
    <div>
      <AggConfiguracion data={Db} createData={createData} />
    </div>
  );
};
