import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { helpHttp } from "../../Recursos/helpHttp";
let api = helpHttp();

export const DatalistEdit = ({ placeholder ,name, valorInicial, handleChange, label, url }) => {

  const [data, setData] = useState([]);

  const cargarConfiguracion = async () => {
    var res = await api.get(url)
    if (res.success) {
      setData(res.value);
    }
  } 

  useEffect(() => {
  
    cargarConfiguracion()
 
  }, []);


  if(valorInicial){
    var opcion = (data.filter(option => option.id === valorInicial))[0];
    if(opcion){
      handleChange({ target: { name: name, value : `${opcion.id} ${opcion.descripcion}` }} )
    }
  }



  return (
    <>
      <Form.Label style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }} >
        {label} <span style={{ color: "red" }}>*</span>
      </Form.Label>

      <Form.Control
        className="input"
        type="text"
        value={valorInicial}
        placeholder={placeholder}
        name={name} 
        onChange={handleChange}
        list="municipio"
      />
      <datalist id="municipio">
        {data 
          ? data.map((el) => ( <option value={el.id+ " " + el.descripcion}  key={el.id}/> ))
          : null
        }
      </datalist>
    </>
  );
};
