import React from "react";
import "./App.css";
import Home from "./Paginas/Home";
import Login from "./Paginas/Login/Login";
import {
  BrowserRouter as Router,
  HashRouter,
  Switch,
  Route,
} from "react-router-dom";
import { AuthProvider } from "./Recursos/AuthContext";
import { AuthRoute } from "./Recursos/AuthRoute";
import NotFound from "./Paginas/404/404";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Switch>
          <Route exact path="/" component={Login} />
          <AuthRoute path="/Home">
            <Home />
          </AuthRoute>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </AuthProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
