import React, { useState, useEffect } from "react";
import "./IframStyle.css";
export const Iframe = () => {
  const [Iframe, setIframe] = useState("Notificaciones");
  
  useEffect(() => {
    setIframe(Iframe + Date.now());
  }, []);

  return (
    <div className=" iframeContent">
      <iframe
        name={Iframe}
        src="https://soporte.apl.com.co/?page_id=553"
      ></iframe>
    </div>
  );
};
