import React from "react";
import { Form} from "react-bootstrap";
import "./Number.css"
export const InputEdit = ({ className,disabled,type, placeholder, name, value, handleChange, handleKeyDown, label, id, classcss }) => {
  return (
    <>
        <Form.Label style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }} >
          {label}<span style={{ color: "red" }}>*</span>
        </Form.Label>
        <Form.Control
          disabled={disabled}
          className={className}
          type={type === null || type === "text"  ? "text" : type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          id={id}
        />
    </>
  );
};
