import React, { useState, useEffect, createContext } from "react";
import Select from "react-select";
import { helpHttp } from "../../Recursos/helpHttp";
import { Form, Button, Col, Row, FormControl } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./DevengadosDeducciones.css";
import { Loader } from "../../Recursos/Loader";
import Alertas from "../../Recursos/Alertas";

const api = helpHttp();
const months = [
  "Ene",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];
const weekDays = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];
/* -------------------------------------------------ESTILOS DEL REACT-SELECT------------------------------------------------- */
const colourStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? '#007bff' : "white",
        color:"black",
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
  
  };

export const DevengadosDeducciones = ({
  endPoint,
  conceptoValues,
  setConceptoValues,
  valoresIniciales,
}) => {
  const [listDevengados, setListDevengados] = useState([]); 
  const [cargando, setCargando] = useState(true);
  var init = null;

  useEffect(() => {
    async function ObtenerDevengados() {
      var res = await api.get(endPoint);
      if (res.success) {
        var con = res.value.map(function (x) {
          return {
            label: x.descripcion,
            tipoDato: x.tipoDatoId,
            tieneValor: x.tieneValor,
            tipo: x.id,
            pagoMin: x.pagoMin,
            pagoMax: x.pagoMax,
            cantidadMin: x.cantidadMin,
            cantidadMax: x.cantidadMax,
          };
        })
        setListDevengados(con)
      }
      if(listDevengados !== []){
        mapValoresIniciales();
      } 
    }
    ObtenerDevengados();
  }, [cargando]);

  const mapValoresIniciales = () => {
    if (valoresIniciales) {

      init = valoresIniciales.map(function (x) {
        var concepto = listDevengados.filter((w) => w.tipo === x.tipoDevengadoId || w.tipo === x.tipoDeduccionId)[0];

        return {
          label: concepto.label,
          tieneValor: concepto.tieneValor,
          tipo: concepto.tipo,
          cantidad: x.cantidad,
          pago: x.pago,
          tipoDato: concepto.tipoDato,
          pagoMin: concepto.pagoMin,
          pagoMax: concepto.pagoMax,
          cantidadMin: concepto.cantidadMin,
          cantidadMax: concepto.cantidadMax,
          id: x.id,
        };
      });

      setConceptoValues(init);
      setTimeout(() => { setCargando(false) }, 2000);
    }else{
      setCargando(false)
    }
  };

  let handleChange = (i, e) => {
    const regex = /[^0-9]+/gm //solo numero
    let valor = e.target.value.replace(regex, "");
    let newConceptoValues = [...conceptoValues];
    let concepto = newConceptoValues[i]; 

    let vMin = e.target.name === "cantidad" ? concepto.cantidadMin : concepto.pagoMin;
    let vMax = e.target.name === "cantidad" ? concepto.cantidadMax : concepto.pagoMax;
 
    if((vMin === null && vMax === null) || (valor >= vMin && valor <= vMax)){
      e.target.value = valor;
      newConceptoValues[i][e.target.name] = valor;
      setConceptoValues(newConceptoValues);
    }
    else {
      Alertas.toastError(`Valor no valido debe estar dentro del rango (${vMin} - ${vMax})`)
    }
    
  };

  let conceptoChange = (i, e) => {
    let newConceptoValues = [...conceptoValues];
    newConceptoValues[i] = e;
    setConceptoValues(newConceptoValues);
  };

  let fechaChange = (i, e) => {
    let newConceptoValues = [...conceptoValues];
    if (e.length === 2) {
      newConceptoValues[i].fechaInicio = new Date(
        e[0].year,
        e[0].monthIndex,
        e[0].day
      );
      newConceptoValues[i].fechaFin = new Date(
        e[1].year,
        e[1].monthIndex,
        e[1].day
      );
    }
    setConceptoValues(newConceptoValues);
  };

  let addFormFields = () => {
    setConceptoValues([...conceptoValues, { label: "Seleccione ..." }]);
  };

  let removeFormFields = (i) => {
    let newConceptoValues = [...conceptoValues];
    newConceptoValues.splice(i, 1);
    setConceptoValues(newConceptoValues);
  };
 

  return (
    <>
      <Form.Row>
        <Col lg="5">
          <Form.Label style={{ fontSize: "15px", fontWeight: "700", }}>
            CONCEPTO<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col lg="3"></Col>
        <Col lg="3">
          <Form.Label style={{ fontSize: "15px", fontWeight: "700", }}>
            VALOR<span style={{ color: "red" }}>*</span>
          </Form.Label>
        </Col>
        <Col lg="1"></Col>
      </Form.Row>
      {(cargando
        ? <Loader />
        : conceptoValues.map((element, index) => (
            <div key={index} id={"devengado_" + index}>
              <Form.Row style={{ textAlign: "left" }} className="mb-3">
                <Col lg="5">
                  <div className="customHeightWidth">
                    <Select
                      className="input"
                      options={listDevengados}
                      styles={colourStyles}
                      onChange={(e) => conceptoChange(index, e)}
                      value={conceptoValues[index]}
                    />
                  </div>
                </Col>
                <Col lg="3">
                  {element.tipoDato === 1 ? (
                    <div className="customDatePickerWidth">
                      <DatePicker
                        format="DD/MM/YYYY"
                        range
                        months={months}
                        weekDays={weekDays}
                        render={(value, openCalendar) => {
                          return (
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                                value={value}
                                onClick={openCalendar}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text">
                                  &#x1f4c5;
                                </span>
                              </div>
                            </div>
                          );
                        }}
                        plugins={[<DatePanel />]}
                        onChange={(e) => fechaChange(index, e)}
                      />
                    </div>
                  ) : element.tipoDato === 2 || element.tipoDato === 4 ? (
                    <Form.Control
                      name="cantidad"
                      type="text"
                      placeholder={element.tipoDato === 2 ? "Dias" : "Horas"}
                      value={element.cantidad}
                      // onKeyDown={(e) => numeroEntero(e)}
                      onChange={(e) => handleChange(index, e)}
                    />
                  ) : (null)
                  }
                </Col>
                <Col lg="3">
                  {element.tieneValor ? (
                    <Form.Control
                      name="pago"
                      type="number"
                      placeholder="$"
                      value={element.pago}
                      onChange={(e) => handleChange(index, e)}
                    />
                  ) : <Form.Control disabled type="text" />}
                </Col>
                <Col style={{display:"flex", justifyContent:"end"}} >
                  <button
                    type="button"
                    className="btn btn-danger btn-rojo"
                    onClick={() => removeFormFields(index)}
                  >
                    <i className="far fa-trash-alt"></i>
                  </button>
                </Col>
              </Form.Row>
            </div>
          ))
      )}
      <Form.Row >
        <Col></Col>
        <Col md="auto">
          <button
            className="btn btn-success btn-verde"
            type="button"
            onClick={() => addFormFields()}
          >
            &#9546;&ensp;&ensp; Agregar
          </button>
        </Col>
      </Form.Row>
    </>
  );
};
