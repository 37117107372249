import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap"; 
import { helpHttp } from "../../Recursos/helpHttp";
let api = helpHttp();


export const InputSelect = ({ name, values, handleChange, label, url}) => {

  const [data, setData] = useState([]);
 
  useEffect(() => {

    const cargarConfiguracion = async () => {
      var res = await api.get(url)
      if (res.success) {
        setData(res.value);
      }
    }
    
    cargarConfiguracion()

    if(values){
      values = (data.filter(option => option === values))[0];
    }

  }, []);

  

  return (
    <>
      <Form.Label style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }} >
        {label} <span style={{ color: "red" }}>*</span>
      </Form.Label>
      <Form.Control
        className="input"
        as="select"
        defaultValue="Seleccionar"
        name={name}
        value={values}
        onChange={handleChange} >
        <option value={"0"}>Seleccionar...</option>
        {data 
          ? data.map((el) => (
            <option value={el.id} key={el.id}>
              {el.descripcion}
            </option>
          ))
        :null}
      </Form.Control>
    </>
  );
};
