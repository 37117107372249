import React, { useEffect, useState } from "react";
import { Switch, Route, } from "react-router-dom";
import "./Home.css";
import { Menu } from "../Componentes/Menu/Menu";
import { Header } from "../Componentes/Header/Header";
import Footer from "../Componentes/Footer/Footer";
import { ListaEmpleado } from "./ListaEmpleados/ListaEmpleado";
import { AggEmpresa } from "./AggEmpresa";
import { Configuracion } from "../Paginas/Configuracion/Configuracion";
import { ListaNomina } from "./Nomina/ListaNomina";
import Habilitacion from "./Habilitacion/Habilitacion";
import { VerEmpresa } from "./VerEmpresa";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { helpHttp } from "../Recursos/helpHttp";
import { ListaNominaAjuste } from "../Paginas/NominaAjuste/ListaNominaAjuste";
import { ListaNominaEliminacion } from "../Paginas/NominaAjuste/ListaNominaEliminacion";
import Alertas from "../Recursos/Alertas";
import Usuario from "./Usuario/Usuario";
import { CargePlantillaEmpleados } from "../Paginas/CargeArchivos/CargePlantillaEmpleados";
import { Iframe } from "../Componentes/Iframe/Iframe";

const alerta = withReactContent(Swal);

function Home() {
  let api = helpHttp();

  const [usuario, setUsuario] = useState("");

  useEffect(() => {
    const cargarConfiguracion = async () => {
      var res = await api.get("Configuracion");
      var user = JSON.parse(localStorage.getItem("user"));

      if (res.success) {
        user.tipoAmbiente = res.value.ambienteId;
      } else {
        user.tipoAmbiente = 1;
      }
      localStorage.setItem("user", JSON.stringify(user));
      setUsuario(user);
    };
    cargarConfiguracion();
  }, []);

  const newClave = () => {
    if (usuario.nuevo) {
      alerta
        .fire({
          icon: "info",
          title: "Cambiar contraseña",
          inputLabel:
            "escriba la contraseña con la cual quiere acceder al sistema",
          input: "password",
          confirmButtonColor: "#198754",
          confirmButtonText: "Aceptar",
        })
        .then(async (result) => {
          if (result.isConfirmed && result.value) {
            let options = {
              headers: { "content-type": "application/json" },
              body: { nuevaClave: result.value },
            };

            var res = await api.put("Usuario/Clave/Nuevo", options);

            if (res.success) {
              alerta.fire({
                icon: "success",
                title: "Se actualizo la contraseña correctamente",
                confirmButtonColor: "#198754",
              });
              usuario.nuevo = false;
              localStorage.setItem("user", JSON.stringify(usuario));
            } else {
              newClave();
            }
          } else {
            Alertas.toastInfo("Recuerde cambiar la clave por defecto");
          }
        });
    }
  };

  newClave();

  return (
    <div id="wrapper">
      <Menu />
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header />
          <div className="container-fluid">
            <Switch>
              <Route path="/Home/Empresa/Config" component={Configuracion} />
              <Route path="/Home/Empresa/Agregar" component={AggEmpresa} />
              <Route path="/Home/Empresa/Verdatos" component={VerEmpresa} />
              <Route path="/Home/Lista/Empleados" component={ListaEmpleado} />
              <Route
                path="/Home/Nominas/Individual/Lista"
                component={ListaNomina}
              />
              <Route
                path="/Home/Nominas/Ajuste/Lista"
                component={ListaNominaAjuste}
              /> 
              <Route
                path="/Home/Nominas/Eliminada/Lista"
                component={ListaNominaEliminacion}
              />
              <Route path="/Home/Habilitacion" component={Habilitacion} />
              <Route path="/Home/Usuario" component={Usuario} />
              <Route
                path="/Home/carge-plantilla-empleados"
                component={CargePlantillaEmpleados}
              />
              <Iframe />
            </Switch>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
export default Home;
