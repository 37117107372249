import React, { useState, useEffect } from "react";
import { Form, Button, Col, Badge, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Input } from "../../Componentes/Input/Input";
import { InputSelect } from "../../Componentes/Input/InputSelect";
import { InfoPersonal } from "../Empleado/Datos/InfoPersonal";
import { Loader } from "../../Recursos/Loader";
import { helpHttp } from "../../Recursos/helpHttp"; // Componente que llama la api
import "./empresa.css";
import { Datalist } from "../../Componentes/Input/Datalist";
import Alertas from "../../Recursos/Alertas";
import { ToastContainer } from "react-toastify";
import { useHistory } from "react-router";
import nitUtils from '../../Utilidades/nitUtils'


/* Validaciones de Formik */
const validacion = Yup.object().shape({
  //Agregar Empresa
  nit: Yup.number().required("Campo oblogatorio"),
  direccion: Yup.string().required("Campo oblogatorio"),
  rsocial: Yup.string().required("Campo oblogatorio"),
  municipioE: Yup.string().required("Campo obligatorio"),
  tpersonaE: Yup.string().required("Campo obligatorio"),
  //telefonoP: Yup.number().required("Campo obligatorio"),
  //correoE: Yup.string().email().required("Campo obligatorio"),

  /* ------------------------------------------------------------------------------------------------------ */
  //Representante Legal
  nombre: Yup.string().max(50).required("Campo obligatorio"),
  otronombre: Yup.string().max(150),
  apellido: Yup.string().max(50).required("Campo obligatorio"),
  otroapellido: Yup.string().max(50),
  tipodocumento: Yup.string().required("Campo obligatorio"),
  ndocumento: Yup.number()
    .integer("Escriba el numero sin puntos")
    .required("Campo obligatorio"),
  //.positive()

  tpersona: Yup.string().required("Campo obligatorio"),
  //municipio: Yup.string().required("Campo obligatorio"),
  //correo: Yup.string().email("No es un email valido").required("Campo obligatorio"),
  //telefono: Yup.number().positive().integer("Escriba el numero sin puntos").required("Campo obligatorio"),
});

export const Empresa = () => {
  const history = useHistory();
  const [loading, setloading] = useState(false);
  const [exito, setexito] = useState(false);
  var url = "Empresa";
  var api = helpHttp();

  /* Funcion Post para pasarle al componente helpHttp que hace el fecht a la api  */
  const createData = (data) => {
    /*  data.id=Date.now(); */ //EL JSON SERVER LE PONE ID
    let options = {
      body: data,
      headers: { "content-type": "application/json" },
    };

    api.post(url, options).then((res) => {
      if (res.success) {
        setloading(false);
        Alertas.toastSuccess("Empresa creada con exito");
      } else {
        setloading(false);
      }
    });
  };
  
  const digito = (e) => {
    nitUtils.calcularDigito(e.target.value)
  };
  
  return (
    <>
      <Alert variant="warning">
        Los campos marcados con <span style={{ color: "red" }}>*</span> son
        obligatorios
      </Alert>
      <hr />
      <Formik
        initialValues={{
          //Agregar Empresa
          nit: "",
          direccion: "",
          rsocial: "",
          municipioE: "",
          tpersonaE: "",
          telefonoP: "",
          correoE: "",
          /* ---------------------------------------- */
          //Representante Legal
          nombre: "",
          otronombre: "",
          apellido: "",
          otroapellido: "",
          tipodocumento: "",
          ndocumento: "",
          municipio: "",
          tpersona: "",
          correo: "",
          telefono: "",
        }}


        validationSchema={validacion}
        onSubmit={async (datos,{ resetForm }) => {
          let municipioE = datos.municipioE.substring(0, 4);
         
          var data = {
            nit: `${datos.nit}`,
            direccion: datos.direccion,
            dv: document.getElementById("dv").value,
            razonSocial: datos.rsocial,
            municipioId: parseInt(municipioE),
            tipoPersonaId: parseInt(datos.tpersonaE),
            representanteLegal: {
              primerNombre: datos.nombre,
              identificacion: `${datos.ndocumento}`,
              otrosNombres: datos.otronombre,
              primerApellido: datos.apellido,
              segundoApellido: datos.otroapellido,
              correo: datos.correo,
              tipoPersona: parseInt(datos.tpersona),
              municipioId: parseInt(datos.municipioE),
              tipoDocumento: parseInt(datos.tipodocumento),
              contacto: [toString(datos.telefono)],
            },
            correos: [datos.correoE],
            telefonos: [toString(datos.telefonoP)],
          };

          setloading(true);
          createData(data);
          //resetForm()
          /* history.push("/") */
        }}
      >
        {({
          handleSubmit,
          handleChange,
          touched,
          errors,
          values,
          id,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              {/* ----Nit -------*/}
              <Col lg="3">
                <Input 
                  label="Nit"
                  type="number"
                  placeholder=""
                  name="nit"
                  value={values.nit}
                  id="nit"
                  handleChange={ (e) => { handleChange(e); digito(e); }}
                />
                {touched.nit && errors.nit ? (
                  <div className="error">{errors.nit}</div>
                ) : null}
              </Col>
              {/* ----Digito De verificacion------ */}
              <Col md="1">
                <Input
                  label="Dv"
                  type="text"
                  name="dv"
                  disabled="disabled"
                  id="dv"
                />
              </Col>
              {/* ----Direccion------ */}
              <Col md="4">
                <Input
                  label="Dirección"
                  type="text"
                  name="direccion"
                  value={values.direccion}
                  handleChange={handleChange}
                  id="direccion"
                />
                {touched.direccion && errors.direccion ? (
                  <div className="error">{errors.direccion}</div>
                ) : null}
              </Col>
              {/* ----Razon Social------ */}
              <Col lg="4">
                <Input
                  label="Razon Social"
                  type="text"
                 
                  name="rsocial"
                  value={values.rsocial}
                  handleChange={handleChange}
                  id="rsocial"
                />
                {touched.rsocial && errors.rsocial ? (
                  <div className="error">{errors.rsocial}</div>
                ) : null}
              </Col>
            </Form.Row>
            <Form.Row className="separador">
              {/* ----Municipio donde se ubica la empresa ------ */}
              <Col md="4">
                <Datalist
                  label="Municipio De La Empresa"
                  name="municipioE"
                  value={values.municipioE}
                  handleChange={handleChange}
                  id="municipioE"
                  placeholder="Escriba el municipio donde se encuentra la empresa"
                  url="Ubicacion/Municipios"
                />
                {touched.municipioE && errors.municipioE ? (
                  <div className="error">{errors.municipioE}</div>
                ) : null}
              </Col>
              {/* ----Tipo de persona ------ */}
              <Col md="4">
                <InputSelect
                  label="Tipo De Persona"
                  name="tpersonaE"
                  value={values.tpersonaE}
                  handleChange={handleChange}
                  url="Persona/TipoPersona"
                  id="tipersonaE"
                />
                {touched.tpersonaE && errors.tpersonaE ? (
                  <div className="error">{errors.tpersonaE}</div>
                ) : null}
              </Col>
              {/* ----Telefono Empresa ------ */}
              <Col>
                <Input
                  label="Telefono Empresa"
                  type="number"
                 
                  name="telefonoE"
                  value={values.telefonoP}
                  handleChange={handleChange}
                  id="telefonoE"
                />
                {touched.telefonoP && errors.telefonoP? (
                  <div className="error">{errors.telefonoP}</div>
                ) : null}
              </Col>
            </Form.Row>
            <Form.Row className="separador justify-content-md-start">
              <Col md="4">
                <Input
                  label="Correo de la empresa"
                  type="text"
                
                  name="correoE"
                  value={values.correoE}
                  handleChange={handleChange}
                />
                {touched.telefonoP && errors.telefonoP ? (
                  <div className="error">{errors.telefonoP}</div>
                ) : null}
              </Col>
            </Form.Row>
            <hr style={{ marginTop: "80px" }} />
            {/* --------Representate Legal------------------------------------- */}
            <h1>
              <Badge className="tituloPrincipal" variant="light">
                REPRESENTANTE LEGAL
              </Badge>
            </h1>
            <InfoPersonal
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              values={values}
            />
            <hr style={{ marginTop: "80px" }} />

            <Alert variant="warning">
              Los campos marcados con <span style={{ color: "red" }}>*</span> son
              obligatorios
            </Alert>
            {loading && <Loader />}
            <Button
              type="reset"
              onClick={resetForm}
              size="lg"
              className="separador btn-verde"
              style={{ marginRight: "10px" }}
              variant="success"
            >
              Limpiar
            </Button>
            <Button size="lg" className="btn btb-primary"a type="submit">
              Guardar
            </Button>
            {/* Mensaje de envio correcto */}
            {exito && (
              <Alert style={{ marginTop: "10px" }} variant="success">
                Datos enviados
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
