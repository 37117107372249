
import { useState } from "react";

import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


const meses = [
    ["Enero", "Ene"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "May"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sep"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],,
  ];

  const weekDays = ["DO", "LU", "MA", "MI", "JU", "VI", "SA"];

export const RangoFechas = ( { mes, setMes }) =>{
 
 
    

    return <>
        <div className="customDatePickerWidth">
            <DatePicker 
                onlyMonthPicker
                // multiple
                months={meses} 
                format="MM/YYYY"
                // range
                // weekDays={weekDays}
                render={(value, openCalendar) => {
                return (
                    <div
                    className="input-group "
                    style={{ cursor: "pointer" }}
                    >
                    <input
                        type="text"
                        className="form-control text-center"
                        placeholder="dd/mm/aaaa ~ dd/mm/aaaa"
                        value={value}
                        onClick={openCalendar}
                        readOnly
                    />
                    <div className="input-group-append">
                        <span
                        className="input-group-text"
                        onClick={openCalendar}
                        >
                        &#x1f4c5;
                        </span>
                    </div>
                    </div>
                );
                }}
                // plugins={[<DatePanel />]} 
                value={mes}
                onChange={ o => {setMes(o)}}/>
        </div>
    </>
}