import React from "react";
import { Form, Col } from "react-bootstrap";
import { InputSelect } from "../../../Componentes/Input/InputSelect"; 
export const InfoPersonal = ({ handleChange, values, errors, touched, id }) => {
  
  return (
    <>
      {/* Primera Fila */}
      <Form.Row>
        <Col lg={4} xl={3}>
          <Form.Label style={{ fontSize: "15px", fontWeight: "700", padding: "10px" }} >
            Primer Nombre<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            type="text"
            
            name="nombre"
            value={values.nombre}
            onChange={handleChange}
            id="nombre1"
          />
          {errors.nombre && touched.nombre ? (
            <div className="error">{errors.nombre}</div>
          ) : null}
        </Col>
        <Col lg={4} xl={3}>
          <Form.Label className="label-input">
            Otros Nombres
          </Form.Label>
          <Form.Control
            className="input"
            type="text"
            
            name="otronombre"
            value={values.otronombre}
            onChange={handleChange}
            id="nombre2"
          />
        </Col>
        <Col lg={4} xl={3}>
          <Form.Label className="label-input">
            Primer Apellido<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
          
            name="apellido"
            value={values.apellido}
            onChange={handleChange}
            id="apellido1"
          />
          {errors.apellido && touched.apellido ? (
            <div className="error">{errors.apellido}</div>
          ) : null}
        </Col> 
        <Col lg={4} xl={3}>
          <Form.Label className="label-input">
            Segundo Apellido
          </Form.Label>
          <Form.Control
            className="input"
            
            name="otroapellido"
            value={values.otroapellido}
            onChange={handleChange}
            isInvalid={!!errors.otroapellido}
            id="apellido2"
          />
        </Col>
        <Col lg={4} xl={3}>
          <InputSelect
            name="tipodocumento"
            value={values.tipodocumento}
            handleChange={handleChange}
            label="Tipo De Documento"
            url="Persona/TipoDocumento"
            id="documento"
          />
          {errors.tipodocumento && touched.tipodocumento ? (
            <div className="error">{errors.tipodocumento}</div>
          ) : null}
        </Col>
        <Col lg={4} xl={3}>
          <Form.Label className="label-input">
            Numero De Documento<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            type="number"
           
            name="ndocumento"
            value={values.ndocumento}
            onChange={handleChange}
            id="ndocu"
          />
          {errors.ndocumento && touched.ndocumento ? (
            <div className="error">{errors.ndocumento}</div>
          ) : null}
        </Col>  
        <Col lg={4} xl={2}>
          <InputSelect
            name="tpersona"
            label="Tipo De Persona"
            id="tpersonaP"
            value={values.tpersona}
            handleChange={handleChange}
            url="Persona/TipoPersona"
          />
          {errors.tpersona && touched.tpersona ? (
            <div className="error">{errors.tpersona}</div>
          ) : null}
        </Col> 
        <Col lg={4} xl={4}>
          <Form.Label className="label-input">
            Correo<span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            className="input"
            type="text"
            name="correo"
            value={values.correo}
            onChange={handleChange}
            id="correoP"
          /> 
        </Col> 
      </Form.Row> 
    </>
  );
};
